<app-toolbar></app-toolbar>
<div class="toolbar-content-spacer"></div>
<div *ngIf="globals?.appConfig?.environment !== 'production'" class="toolbar-content-spacer-test"></div>
<div class="table-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner diameter="25"></mat-spinner>
</div>

<div class="search-header">
  <div #searchContainer class="mat-search mat-typography" fxLayout="row" fxLayoutAlign="start middle">
    <mat-icon class="search-icon">search</mat-icon>
    <input
      #searchInputField
      tabindex="1"
      class="search-input mat-typography"
      placeholder="{{ 'app.pages.employees.placeholderSearchFilter' | translate }}"
      type="text"
      cdkFocusInitial
      (keyup)="applyFilter($event.target.value)"
      [(ngModel)]="searchValue"
    />
  </div>
</div>

<div class="table-container">
  <div class="table-definition-container">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table-table"
      matSort
      matSortActive="firstName"
      matSortDisableClear
      matSortDirection="asc"
    >
      <ng-container matColumnDef="isAtWork">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="isAtWork">
          {{ 'app.pages.employees.tableColTitleStatus' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" (click)="onSelect(row)">
          <shared-avatar
            [size]="avatarSize.Small"
            [gravatarEmail]="row.email"
            [status]="globals.getAvatarStatus(row)"
          ></shared-avatar>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
          {{ 'app.pages.employees.tableColTitleFirstName' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" (click)="onSelect(row)">{{ row.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">
          {{ 'app.pages.employees.tableColTitleLastName' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" (click)="onSelect(row)">{{ row.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th fxHide fxShow.gt-sm mat-header-cell *matHeaderCellDef mat-sort-header="phone">
          {{ 'app.pages.employees.tableColTitlePhone' | translate }}
        </th>
        <td fxHide fxShow.gt-sm mat-cell *matCellDef="let row" (click)="onSelect(row)">
          <ng-container *ngIf="getWorkPhoneNumber(row.phones); then showPhoneNumber"></ng-container>
          <ng-template #showPhoneNumber>
            <a mat-button class="employee-phone-number" href="tel:{{ getWorkPhoneNumber(row.phones) }}">{{
              getWorkPhoneNumber(row.phones) | phone
            }}</a>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th fxHide fxShow.gt-sm mat-header-cell *matHeaderCellDef mat-sort-header="email">
          {{ 'app.pages.employees.tableColTitleEmail' | translate }}
        </th>
        <td fxHide fxShow.gt-sm mat-cell *matCellDef="let row" (click)="onSelect(row)">{{ row.email }}</td>
      </ng-container>

      <ng-container matColumnDef="impersonate">
        <th fxHide fxShow.gt-sm mat-header-cell *matHeaderCellDef></th>
        <td fxHide fxShow.gt-sm mat-cell *matCellDef="let row">
          <button
            *ngIf="globals.impersonate?.isAdmin()"
            class="table-admin-button"
            mat-raised-button
            color="accent"
            (click)="impersonate(row)"
          >
            Impersonate
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    #paginator
    [length]="resultsLength"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]"
  ></mat-paginator>
</div>
