import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { ApiService } from '@services/api/api.service';
import { Stats } from '@shared/api/stats.interface';
import { MatDialog } from '@angular/material/dialog';
import { StatsDetailedInfoDialogComponent } from '@components/dialogs/stats-detailed-info-dialog/stats-detailed-info-dialog.component';
import { Globals } from '@shared/globals';
import { Time } from '@shared/time';
import { StatsFiltered } from '@shared/api/stats-filtered.interface';
import { HttpErrorResponse } from '@angular/common/http';
// tslint:disable-next-line:max-line-length
import { StatsDetailedInfoOvertimeDialogComponent } from '@components/dialogs/stats-detailed-info-overtime-dialog/stats-detailed-info-overtime-dialog.component';
import { StatsOvertime } from '@shared/stats-overtime.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  environment = environment;
  stats: Stats;
  statsOvertime: StatsOvertime = {
    overtime: undefined,
    weeklyHours: undefined,
    workingHoursThisWeek: undefined,
    validityDate: undefined
  };
  overtimeInHours: Time;
  weeklyHours: Time;
  paidLeaveTooltipPos = 'right';

  isLoading = false;

  // weekly stats
  today = new Date(Date.now());
  statsDate: Date;
  weeklyWorktime: Time;
  globals: Globals;

  constructor(
    public detailedInfo: MatDialog,
    public detailedOvertimeInfo: MatDialog,
    private _authGuardService: AuthGuardService,
    private _apiService: ApiService,
    private _globals: Globals,
  ) {
    this.globals = this._globals;
  }

  ngOnInit() {
    this.isLoading = true;
    this._globals.isLoading = true;

    this.today = new Date(Date.now());

    this.getStats();
    this.getWeeklyStats(this.today);
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  onClick() {
    this._authGuardService.logout();
  }

  onOpenStatsDetailedOvertimeInfoDialog() {
    const dialogRef = this.detailedOvertimeInfo.open(
      StatsDetailedInfoOvertimeDialogComponent,
      {
        data: this.statsOvertime,
        panelClass: 'app-dialog-style'
      }
    );

    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {}));
  }

  onOpenStatsDetailedInfoDialog() {
    const dialogRef = this.detailedInfo.open(StatsDetailedInfoDialogComponent, {
      data: this.stats,
      panelClass: 'app-dialog-style'
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {}));
  }

  getStats() {
    this.subscriptions.add(
      this._apiService.getStats().subscribe(
        data => {
          this.stats = data as Stats;
          this.isLoading = false;
          this._globals.isLoading = false;

          this.statsOvertime.overtime = this.stats.overtimeToLastWeek;
          this.statsOvertime.weeklyHours = this.stats.weeklyWorkingTime;

          this.weeklyHours = new Time();
          this.weeklyHours = Time.parseNumber(this.stats.weeklyWorkingTime);

          this.overtimeInHours = new Time();
          this.overtimeInHours = Time.parseNumber(
            this.stats.overtimeToLastWeek
          );
        },
        error => {
          console.warn(error);
          this.isLoading = false;
          this._globals.isLoading = false;
        }
      )
    );
  }

  getWeeklyStats(date: Date) {
    date.setHours(0, 0, 0);
    const lastMonday = new Date(date);

    let dayOfTheWeek = lastMonday.getDay() - 1;
    if (dayOfTheWeek < 0) {
      dayOfTheWeek = 6;
    }
    lastMonday.setDate(lastMonday.getDate() - dayOfTheWeek);

    const nextMonday = new Date(lastMonday);
    nextMonday.setDate(nextMonday.getDate() + 7);

    this.statsDate = new Date(lastMonday);
    this.statsOvertime.validityDate = new Date(this.statsDate);

    this.subscriptions.add(
      this._apiService.getStats(lastMonday, nextMonday).subscribe(
        data => {
          const worktime = (data as StatsFiltered).workHours;
          this.weeklyWorktime = new Time();
          this.weeklyWorktime = Time.parseNumber(worktime);
          this.statsOvertime.workingHoursThisWeek = worktime;
        },
        error => {
          if ((error as HttpErrorResponse).status === 401) {
            console.log('Unauthorized access please login again.');
            this._authGuardService.logout();
          }
          console.warn(error);
          this.isLoading = false;
          this._globals.isLoading = false;
        }
      )
    );
  }
}
