<mat-progress-bar color="primary" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="dialog-content action-row-bottom-fix">
  <h1 mat-dialog-title><span>{{'app.pages.workHours.newAllDayEntryTitle' | translate}}</span></h1>
  <div mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field class="date-picker">
          <input matInput [formControl]="selectedFromDateFormControl" [matDatepicker]="fromPicker" placeholder="{{'app.pages.workHours.placeholderChooseDate' | translate}}" (dateChange)="setFromDate($event)">
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker weekStartsOn="1" touchUi="true" #fromPicker></mat-datepicker>
        </mat-form-field>
        <!--
        <mat-form-field>
          <input matInput [formControl]="selectedToDateFormControl" [matDatepicker]="toPicker" placeholder="To date" (dateChange)="setToDate($event)" i18n-placeholder>
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker weekStartsOn="1" touchUi="true" #toPicker (selectedChange)="setDate($event)"></mat-datepicker>
        </mat-form-field>
         -->
      </div>

      <mat-form-field>
        <mat-select #absenceTypeSelect placeholder="{{'app.pages.workHours.placeholderTypeOfAbsence' | translate}}" [formControl]="optionAbsenceFormControl">
          <mat-option *ngFor="let element of globals?.entryDefinition.absenceAllDay | keyvalue" value="{{element.key}}">{{element.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions position="end">
    <mat-divider></mat-divider>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close [disabled]="isLoading">{{'app.btnCancel' | translate}}</button>
    <button mat-button cdkFocusInitial color="primary" [disabled]="isLoading" (click)="onSaveWorkEntry($event)">{{'app.btnSave' | translate}}</button>
  </mat-dialog-actions>
</div>
