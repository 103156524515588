<div id="avatar" class="div__avatar">
  <div class="div__avatar-wrapper" [ngStyle]="{ 'border-color': status }">
    <img
      [src]="getGravatar()"
      class="img__avatar avatar-border"
      [ngClass]="{
        'avatar--tiny': avatarSize.Tiny === size,
        'avatar--small': avatarSize.Small === size,
        'avatar--medium': avatarSize.Medium === size,
        'avatar--large': avatarSize.Large === size
      }"
    />
  </div>
  <span
    class="span__badge avatar-border"
    [ngClass]="{
      'span__badge--tiny': avatarSize.Tiny === size,
      'span__badge--small': avatarSize.Small === size,
      'span__badge--medium': avatarSize.Medium === size,
      'span__badge--large': avatarSize.Large === size
    }"
    [ngStyle]="{ 'background-color': status }"
  ></span>
</div>
