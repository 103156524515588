import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Stats } from '@shared/api/stats.interface';

@Component({
  selector: 'app-stats-detailed-info-dialog',
  templateUrl: './stats-detailed-info-dialog.component.html',
  styleUrls: ['./stats-detailed-info-dialog.component.css']
})
export class StatsDetailedInfoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StatsDetailedInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Stats) { }

  ngOnInit() { }

}
