<div fxLayout="row" class="birthday-block">
  <span class="avatar-margin">
    <shared-avatar
      [size]="avatarSize.Medium"
      [gravatarEmail]="birthday.gravatarEmail"
      [status]="globals.getAvatarStatus(birthday.contact)"
    ></shared-avatar>
  </span>
  <span fxLayout="column">
    <span class="mat-body-strong birthday-name">{{ birthday.name }}</span>
    <span fxLayout="row">
      <span class="mat-body">{{ birthday.date | date: 'dd. MMMM' }}</span>
      <span class="today-badge today-badge-background mat-body-strong" *ngIf="birthday.isToday">{{
        'app.pages.home.statsBirthdayTodayBadge' | translate
      }}</span>
    </span>
  </span>
</div>
