import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { AppConfig } from '@shared/app-config/app-config';
import { LocalStorageKeys } from '@shared/global-settings/local-storage-keys';
import { LocalStorageObjects } from '@shared/global-settings/local-storage-objects';
import { LocaleSettings } from '@shared/locale-settings/locale-settings';
import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { Token } from '@shared/api/token';
import { Impersonate } from '@shared/global-settings/impersonate';
import { CustomThemes } from '@shared/global-settings/themes';
import { Themes } from '@shared/global-settings/themes.enum';
import { EntryTypes } from '@shared/api/entry-types';
import { BehaviorSubject } from 'rxjs';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';
import { EmployeeContact } from './api/employee-contact.interface';

@Injectable()
export class Globals implements OnInit {
  // basic application configuration
  appConfig: AppConfig;
  entryDefinition = new EntryTypes();
  ui = new CustomThemes();
  userStatus$ = new BehaviorSubject(AvatarStatus.Offline);

  // localStorage setup
  localStorageKeys = new LocalStorageKeys();
  sessionStorageData = new LocalStorageObjects();

  // locale settings
  localeSettings = new LocaleSettings();

  // global loading indicator
  isLoading = false;

  // global enable Hotkey indicator
  hotkeyEnabled = true;

  // shared objects
  token: Token;
  impersonate: Impersonate;
  sharedEntries = new Array<WorkHourEntry>();

  appConfigInit$ = new BehaviorSubject<AppConfig>(undefined);
  impersonatingStarted$ = new BehaviorSubject<boolean>(false);
  // ToDo: stuff below MUST be changed to BehaviourSubject!
  @Output() toggleSidebarEvent = new EventEmitter();
  @Output() themeChangedEvent = new EventEmitter();

  constructor() {
    // set local storage keys
    this.localStorageKeys.user = 'acx_user';
    this.localStorageKeys.bearerToken = 'acx_access_token';
    this.localStorageKeys.sessionId = 'acx_session_id';
    this.localStorageKeys.data.uiTheme = 'acx_ui_theme';
    this.localStorageKeys.data.workHourEntry = 'acx_hrportal_whe';
    this.localStorageKeys.data.allDayAbsence = 'acx_hrportal_whe_all_day';
    this.localStorageKeys.data.timeBasedAbsence = 'acx_hrportal_whe_time_based';

    // setup themes
    this.ui.themes.push(Themes.ACXLightTheme);
    this.ui.themes.push(Themes.ACXDarkTheme);

    this.ui.currentTheme = Themes.ACXLightTheme;
    this.ui.isDarkTheme = false;
  }

  ngOnInit() {}

  getAvatarStatus(employeeContact: EmployeeContact): AvatarStatus {
    // not at work == offline
    if (employeeContact.isAtWork === false) {
        return AvatarStatus.Offline;
    }
    // is at work but no text set (didnt check in via hr portal ui client)
    if (employeeContact.isAtWork && !employeeContact.status?.text) {
        return AvatarStatus.Online;
    }
    // is at work and has status text, try and map to known status
    if (employeeContact.isAtWork && employeeContact.status?.text) {
        switch (employeeContact.status.text) {
            case AvatarStatus.Offline:
                return AvatarStatus.Offline;
            case AvatarStatus.Online:
                return AvatarStatus.Online;
            case AvatarStatus.Away:
                return AvatarStatus.Away;
        }
    }
    // in any other case just assume offline
    return AvatarStatus.Offline;
  }

  setTheme(newTheme: Themes, isDarkTheme: boolean, oldTheme?: Themes) {
    if (oldTheme) {
      document.body.classList.remove(oldTheme);
    }

    document.body.classList.add(newTheme);

    if (!this.ui) {
      this.ui = new CustomThemes();
    }

    this.ui.currentTheme = newTheme;
    this.ui.isDarkTheme = isDarkTheme;
    this.ui.saveThemeSettings(this.localStorageKeys.data.uiTheme);
    this.themeChangedEvent.emit(newTheme);
  }
}
