import { Component, OnInit, AfterViewInit,  Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { formatISO } from 'date-fns';

// shared types
import { Globals } from '@shared/globals';
import { Time } from '@shared/time';
import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { TicketReference } from '@shared/api/ticket-reference.interface';
import { WorkHourEntryResponse } from '@shared/api/work-hour-entry-response.interface';
import { AppDialogDataWorkEntry } from '@shared/app-dialog-data-work-entry.interface';
import { TicketSourceType } from '@shared/api/enum/ticket-source-type.enum';

// services
import { ApiService } from '@services/api/api.service';
import { UserInfoService } from '@services/user-info.service';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-hour-entry-dialog',
  templateUrl: './work-hour-entry-dialog.component.html',
  styleUrls: ['./work-hour-entry-dialog.component.css']
})
export class WorkHourEntryDialogComponent implements OnInit, AfterViewInit {
  dialogData: AppDialogDataWorkEntry;
  globals: Globals;
  isLoading = false;

  // form data
  fromTime = new Time();
  fromTimeString = this.fromTime.toString('hh:mm');
  dateTime = new Date(Date.now());
  toTime = new Time();
  toTimeString = this.toTime.toString('hh:mm');
  id: string;

  // translate strings
  messageVerifyTime: string;
  messageVerifyTicket: string;
  messageVerifyDescription: string;
  messageVerifyDescriptionLength: string;
  messageCantEditEntry: string;
  messageCantCreateEntry: string;

  // formControls
  formControlEntryType = new FormControl();
  formControlEntryActivity = new FormControl();

  patternTicketId     = '^[1-9]([0-9]{1,4})?$';
  formControlTicketId = new FormControl('', [
    Validators.pattern(this.patternTicketId),
    Validators.required
  ]);

  formControlDescription = new FormControl('', [
    Validators.maxLength(256),
    Validators.required
  ]);

  @ViewChild('workTypeSelect', { static: true }) workTypeSelect: MatSelect;
  @ViewChild('activityTypeSelect', { static: true }) activityTypeSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<WorkHourEntryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AppDialogDataWorkEntry,
              private _globals: Globals,
              private _apiService: ApiService,
              private _userInfoService: UserInfoService,
              private _authGuardService: AuthGuardService,
              private _translateService: TranslateService) {
    this.dialogData = data;
    this.globals = _globals;
  }

  ngOnInit() {
    if ((this.dialogData.edit === true) || (this.dialogData.content)) {
      this.id = this.dialogData.content.id;
      this.formControlTicketId.setValue(parseInt(this.dialogData.content.ticketReferences[0].identification, 10));
      this.formControlDescription.setValue(this.dialogData.content.description);

      // set entry type
      this._globals.entryDefinition.workEntryTypes.forEach((val, key, map) => {
        if (val === this.dialogData.content.type) {
          this.formControlEntryType.setValue(key);
        }
      });

      // set entry activity
      this._globals.entryDefinition.workEntryActivities.forEach((val, key, map) => {
        if (val === this.dialogData.content.activity) {
          this.formControlEntryActivity.setValue(key);
        }
      });

      this.fromTime = Time.parseDateTime(new Date(this.dialogData.content.begin));
      this.toTime   = Time.parseDateTime(new Date(this.dialogData.content.end));
      this.fromTimeString = this.fromTime.toString('hh:mm');
      this.toTimeString   = this.toTime.toString('hh:mm');
    } else {
      let startTime: Date;
      let endTime: Date;

      // check exceptions
      const allDayAbsenceTime = new Date(Date.now());
      allDayAbsenceTime.setHours(2, 0, 0, 0);
      const nullTime = new Date(Date.now());
      nullTime.setHours(0, 0, 0, 0);
      const currentTime = new Date(Date.now());

      if (this.dialogData.presence) {
        // book first of a day entry
        if ((this.dialogData.date.getHours() === nullTime.getHours() || this.dialogData.date.getHours() === allDayAbsenceTime.getHours())
            && this.dialogData.presence.begin) {
          startTime = new Date(this.dialogData.presence.begin);
          endTime = new Date(startTime);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());

          // negclect actual time
          if (startTime.getTime() > endTime.getTime()) {
            endTime.setHours(startTime.getHours() + 1, startTime.getMinutes());
          }
          // add entry
        } else {
          startTime = new Date(this.dialogData.date);
          endTime = new Date(startTime);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());

          // negclect actual time
          if (startTime.getTime() > endTime.getTime()) {
            endTime.setHours(startTime.getHours() + 1, startTime.getMinutes());
          }
          const finishWorkTime = new Date(this.dialogData.presence.end);

          // book entry before presence end
          if (startTime.getTime() < finishWorkTime.getTime()) {
            endTime = finishWorkTime;
          }
        }
      } else {
        // add entry without a presence entry
        if (this.dialogData.date.getHours() !== nullTime.getHours() && this.dialogData.date.getHours() !== allDayAbsenceTime.getHours()) {
          startTime = new Date(this.dialogData.date);
          endTime = new Date(startTime);
          endTime.setHours(startTime.getHours() + 1);
          // book first of a day entry without a presence entry
        } else {
          endTime = new Date(this.dialogData.date);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());
          startTime = new Date(endTime);
          startTime.setHours(currentTime.getHours() - 1, currentTime.getMinutes());
        }
      }

      this.fromTime = Time.parseDateTime(startTime);
      this.toTime   = Time.parseDateTime(endTime);
      this.fromTimeString = this.fromTime.toString('hh:mm');
      this.toTimeString   = this.toTime.toString('hh:mm');

      this.formControlEntryType.setValue('work');
      this.formControlEntryActivity.setValue('progDesignDoc');
    }

    this._translateService.get('app.message.verifyTime')
      .subscribe(text => this.messageVerifyTime = text);

    this._translateService.get('app.message.verifyTicket')
      .subscribe(text => this.messageVerifyTicket = text);

    this._translateService.get('app.message.verifyDescription')
      .subscribe(text => this.messageVerifyDescription = text);

    this._translateService.get('app.message.verifyDescriptionLength')
      .subscribe(text => this.messageVerifyDescriptionLength = text);

    this._translateService.get('app.message.editEntryNotAllowed')
      .subscribe(text => this.messageCantEditEntry = text);

    this._translateService.get('app.message.createEntryNotAllowed')
      .subscribe(text => this.messageCantCreateEntry = text);
  }

  ngAfterViewInit() { }

  onSaveWorkEntry(event: any) {
    this.isLoading = true;

    const fromDateTime = this.fromTime.toDate(this.dialogData.date);
    const toDateTime   = this.toTime.toDate(this.dialogData.date);

    // content checks
    if ((toDateTime.getHours() < fromDateTime.getHours()) ||
        (toDateTime.getHours() <= fromDateTime.getHours()) && (toDateTime.getMinutes() <= fromDateTime.getMinutes())) {

        this._userInfoService.showInfoMessage(this.messageVerifyTime, 2000);
        this.isLoading = false;
        return;
    }

    if (this.formControlTicketId.invalid) {
      this._userInfoService.showInfoMessage(this.messageVerifyTicket, 2000);
      this.isLoading = false;
      return;
    }

    if (this.formControlDescription.invalid) {
      this._userInfoService.showInfoMessage(this.messageVerifyDescription, 2000);
      this.isLoading = false;
      return;
    }

    // all content is ok - go ahead and save
    const utcBegin = new Date(Time.toUtcDate(fromDateTime));
    const utcEnd = new Date(Time.toUtcDate(toDateTime));

    const entry: WorkHourEntry = {
      id: this.id,
      begin: formatISO(fromDateTime),
      end: formatISO(toDateTime),
      type: this._globals.entryDefinition.workEntryTypes.get(this.formControlEntryType.value),
      activity: this._globals.entryDefinition.workEntryActivities.get(this.formControlEntryActivity.value),
      description: this.trimMultilineText(this.formControlDescription.value as string),
      ticketReferences: new Array<TicketReference>({ source: TicketSourceType.Redmine, identification: this.formControlTicketId.value })
    };

    if (this.dialogData.edit === false) {
      // is new entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.postWorkHourEntry(entry)
        .subscribe(
          response => {
            this.isLoading = false;
            entry.id = (response as WorkHourEntryResponse).id;
            dialogResult.content.id = entry.id;
            this.dialogRef.close(dialogResult);
          },
          error => {
            this.handleHttpErrorResponse(error, dialogResult);
            this.isLoading = false;
          }
        );
    } else {
      // edit existing entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(entry.begin),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.putWorkHourEntry(entry)
      .subscribe(
        response => {
          this.isLoading = false;
          entry.id = (response as WorkHourEntryResponse).id;
          this.dialogRef.close(dialogResult);
        },
        error => {
          this.handleHttpErrorResponse(error, dialogResult);
          console.log(error);
          this.isLoading = false;
        }
      );
    }
  }

  handleHttpErrorResponse(error: HttpErrorResponse, data?: any) {
    if (error.status === 401) {
      sessionStorage.setItem(this._globals.localStorageKeys.data.workHourEntry, JSON.stringify(data));
      this.dialogRef.close();
      this._authGuardService.logout();
    }

    if (error.status === 403) {
      if (this.dialogData.edit) {
        this._userInfoService.showInfoMessage(this.messageCantEditEntry, 2500);
      } else {
        this._userInfoService.showInfoMessage(this.messageCantCreateEntry, 2500);
      }
    }
  }

  getTimeString(time: Time): string {
    return time.toString('hh:mm');
  }

  onToTimeChanged(event: any) {
    this.toTime = Time.parseString(event, 'hh:mm');
    this.toTimeString = this.toTime.toString('hh:mm');
  }

  onFromTimeChanged(event: any) {
    this.fromTime = Time.parseString(event, 'hh:mm');
    this.fromTimeString = this.fromTime.toString('hh:mm');
  }

  getDescriptionError(): string {
    if (this.formControlDescription.hasError('maxLength')) {
      return this.messageVerifyDescriptionLength;
    }

    if (this.formControlDescription.hasError('required')) {
      return this.messageVerifyDescription;
    }
  }

  private trimMultilineText(text: string): string {
    return text.replace(new RegExp('\n', 'g'), ' ').replace(new RegExp(' +', 'g'), ' ').trim();
  }
}
