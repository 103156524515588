import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Time } from '@shared/time';
import { AppDialogDataWorkEntry } from '@shared/app-dialog-data-work-entry.interface';
import { MatSelect } from '@angular/material/select';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from '@shared/globals';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { ApiService } from '@services/api/api.service';
import { WorkHourEntryResponse } from '@shared/api/work-hour-entry-response.interface';
import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { TicketReference } from '@shared/api/ticket-reference.interface';
import { UserInfoService } from '@services/user-info.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { formatISO } from 'date-fns';

@Component({
  selector: 'app-time-based-absence-dialog',
  templateUrl: './time-based-absence-dialog.component.html',
  styleUrls: ['./time-based-absence-dialog.component.css']
})
export class TimeBasedAbsenceDialogComponent implements OnInit {
  dialogData: AppDialogDataWorkEntry;
  globals: Globals;
  isLoading = false;

  // base setup
  fromTime = new Time();
  fromTimeString = this.fromTime.toString('hh:mm');
  dateTime = new Date(Date.now());
  toTime = new Time();
  toTimeString = this.toTime.toString('hh:mm');

  // translate strings
  messageVerifyTime: string;
  messageCantEditEntry: string;
  messageCantCreateEntry: string;

  // form controls
  formControlAbsenceType = new FormControl();

  // data
  id: string;

  @ViewChild(MatSelect, { static: true }) absenceTypeSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<TimeBasedAbsenceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AppDialogDataWorkEntry,
              private _globals: Globals,
              private _authGuardService: AuthGuardService,
              private _apiService: ApiService,
              private _userInfoService: UserInfoService,
              private _translateService: TranslateService) {
    this.dialogData = data;
    this.globals = _globals;
  }

  ngOnInit() {
    if ((this.dialogData.edit === true) || (this.dialogData.content)) {
      this.id = this.dialogData.content.id;
      this.fromTime = Time.parseDateTime(new Date(this.dialogData.content.begin));
      this.toTime   = Time.parseDateTime(new Date(this.dialogData.content.end));
      this.fromTimeString = this.fromTime.toString('hh:mm');
      this.toTimeString   = this.toTime.toString('hh:mm');
    } else {
      let startTime: Date;
      let endTime: Date;

      // check exceptions
      const allDayAbsenceTime = new Date(Date.now());
      allDayAbsenceTime.setHours(2, 0, 0, 0);
      const nullTime = new Date(Date.now());
      nullTime.setHours(0, 0, 0, 0);
      const currentTime = new Date(Date.now());

      if (this.dialogData.presence) {
        // book first of a day entry
        if ((this.dialogData.date.getHours() === nullTime.getHours() || this.dialogData.date.getHours() === allDayAbsenceTime.getHours())
            && this.dialogData.presence.begin) {
          startTime = new Date(this.dialogData.presence.begin);
          endTime = new Date(startTime);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());

          // negclect actual time
          if (startTime.getTime() > endTime.getTime()) {
            endTime.setHours(startTime.getHours() + 1, startTime.getMinutes());
          }
          // add entry
        } else {
          startTime = new Date(this.dialogData.date);
          endTime = new Date(startTime);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());

          // negclect actual time
          if (startTime.getTime() > endTime.getTime()) {
            endTime.setHours(startTime.getHours() + 1, startTime.getMinutes());
          }
          const finishWorkTime = new Date(this.dialogData.presence.end);

          // book entry before presence end
          if (startTime.getTime() < finishWorkTime.getTime()) {
            endTime = finishWorkTime;
          }
        }
      } else {
        // add entry without a presence entry
        if (this.dialogData.date.getHours() !== nullTime.getHours() && this.dialogData.date.getHours() !== allDayAbsenceTime.getHours()) {
          startTime = new Date(this.dialogData.date);
          endTime = new Date(startTime);
          endTime.setHours(startTime.getHours() + 1);
          // book first of a day entry without a presence entry
        } else {
          endTime = new Date(this.dialogData.date);
          endTime.setHours(currentTime.getHours(), currentTime.getMinutes());
          startTime = new Date(endTime);
          startTime.setHours(currentTime.getHours() - 1, currentTime.getMinutes());
        }
      }

      this.fromTime = Time.parseDateTime(startTime);
      this.toTime   = Time.parseDateTime(endTime);
      this.fromTimeString = this.fromTime.toString('hh:mm');
      this.toTimeString   = this.toTime.toString('hh:mm');

      this.formControlAbsenceType.setValue('overtimeDeduct');

      this._translateService.get('app.message.verifyTime')
        .subscribe(text => this.messageVerifyTime = text);

      this._translateService.get('app.message.editEntryNotAllowed')
        .subscribe(text => this.messageCantEditEntry = text);

      this._translateService.get('app.message.createEntryNotAllowed')
        .subscribe(text => this.messageCantCreateEntry = text);
    }
  }

  getTimeString(time: Time): string {
    return time.toString('hh:mm');
  }

  onToTimeChanged(event: any) {
    this.toTime = Time.parseString(event, 'hh:mm');
    this.toTimeString = this.toTime.toString('hh:mm');
  }

  onFromTimeChanged(event: any) {
    this.fromTime = Time.parseString(event, 'hh:mm');
    this.fromTimeString = this.fromTime.toString('hh:mm');
  }

  onSaveWorkEntry(event: any) {
    this.isLoading = true;

    const fromDateTime = this.fromTime.toDate(this.dialogData.date);
    const toDateTime   = this.toTime.toDate(this.dialogData.date);

    // content checks
    if ((toDateTime.getHours() < fromDateTime.getHours()) ||
        (toDateTime.getHours() <= fromDateTime.getHours()) && (toDateTime.getMinutes() <= fromDateTime.getMinutes())) {

        this._userInfoService.showInfoMessage(this.messageVerifyTime, 2000);
        this.isLoading = false;
        return;
    }

    // all content is ok - go ahead and save
    const entry: WorkHourEntry = {
      id: this.id,
      begin: formatISO(fromDateTime),
      end: formatISO(toDateTime),
      type: this._globals.entryDefinition.absenceTimeBased.get(this.formControlAbsenceType.value),
      activity: undefined,
      description: this._globals.entryDefinition.absenceTimeBased.get(this.formControlAbsenceType.value),
      ticketReferences: new Array<TicketReference>()
    };

    if (this.dialogData.edit === false) {
      // is new entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.postWorkHourEntry(entry)
        .subscribe(
          response => {
            this.isLoading = false;
            entry.id = (response as WorkHourEntryResponse).id;
            dialogResult.content.id = entry.id;
            this.dialogRef.close(dialogResult);
          },
          error => {
            this.handleHttpErrorResponse(error, dialogResult);
            this.isLoading = false;
          }
        );
    } else {
      // edit existing entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(entry.begin),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.putWorkHourEntry(entry)
      .subscribe(
        response => {
          this.isLoading = false;
          this.dialogRef.close(dialogResult);
        },
        error => {
          this.handleHttpErrorResponse(error, dialogResult);
          console.log(error);
          this.isLoading = false;
        }
      );
    }
  }

  handleHttpErrorResponse(error: HttpErrorResponse, data: any) {
    if (error.status === 401) {
      sessionStorage.setItem(this._globals.localStorageKeys.data.timeBasedAbsence, JSON.stringify(data));
      this.dialogRef.close();
      this._authGuardService.logout();
    }

    if (error.status === 403) {
      if (this.dialogData.edit) {
        this._userInfoService.showInfoMessage(this.messageCantEditEntry, 2500);
      } else {
        this._userInfoService.showInfoMessage(this.messageCantCreateEntry, 2500);
      }
    }
  }
}
