import { Component, OnInit, Input } from '@angular/core';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { Globals } from '@shared/globals';
import { AvatarSize } from './avatar-size.enum';
import { AvatarStatus } from './avatar-status.enum';

@Component({
  selector: 'shared-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  /** Avatar size with several break points (tiny, small, medium, large) */
  @Input() size? = AvatarSize.Small;

  /** Gravatar email address */
  @Input() gravatarEmail?: string;

  /** Avatar status used for badge layout */
  @Input() status? = AvatarStatus.Offline;

  public readonly avatarSize = AvatarSize;
  public readonly avatarStatus = AvatarStatus;
  public readonly badgeSize = this.size / 3;

  constructor(private _globals: Globals, private _gravatarService: GravatarService) {}

  ngOnInit(): void {
    if (!this.status) {
      this.status = AvatarStatus.Offline;
    }
  }

  getGravatar(): string {
    let email = this.gravatarEmail
      ? this.gravatarEmail
      : this._globals.impersonate.user.email ?? this._globals.impersonate.user.email;

    return this._gravatarService.getGravatar(email, this.size);
  }
}
