import { Component, OnInit } from '@angular/core';
import { Globals } from '@shared/globals';
import { ApiService } from '@services/api/api.service';
import { ApiInfo } from '@shared/api/api-info.interface';

@Component({
  selector: 'app-app-info-dialog',
  templateUrl: './app-info-dialog.component.html',
  styleUrls: ['./app-info-dialog.component.css']
})
export class AppInfoDialogComponent implements OnInit {
  globals: Globals;
  apiInfo: ApiInfo;

  constructor(private _globals: Globals,
              private _apiService: ApiService) {
    this.globals = _globals;
  }

  ngOnInit() {
    this._apiService.getApiInfo()
      .subscribe(
        info => {
          this.apiInfo = info as ApiInfo;
        }
      );
  }

  getChangelogUrl() : String {
    return `./CHANGELOG.md?_=${this.globals.appConfig.version}`
  }

  getShortcutsUrl() : String {
    return `./docs/shortcuts.md?_=${this.globals.appConfig.version}`
  }
}
