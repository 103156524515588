<mat-progress-bar color="primary" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="dialog-content action-row-bottom-fix">
  <h1 mat-dialog-title>{{'app.pages.workHours.newTimeBasedEntryTitle' | translate}} {{dialogData.date | date: 'dd. MMMM yyyy'}}</h1>
  <div mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field>
          <input matInput #inputFromTime placeholder="{{'app.pages.workHours.entry.from' | translate}}" type="time" [(ngModel)]="fromTimeString" [value]="fromTimeString" (ngModelChange)="onFromTimeChanged($event)">
        </mat-form-field>
        <mat-form-field>
          <input matInput #inputToTime placeholder="{{'app.pages.workHours.entry.to' | translate}}" type="time" [(ngModel)]="toTimeString" [value]="toTimeString" (ngModelChange)="onToTimeChanged($event)">
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-select #absenceTypeSelect placeholder="{{'app.pages.workHours.entry.type' | translate}}" [formControl]="formControlAbsenceType">
          <mat-option *ngFor="let element of globals?.entryDefinition.absenceTimeBased | keyvalue" value="{{element.key}}">{{element.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions position="end">
    <mat-divider></mat-divider>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close [disabled]="isLoading">{{'app.btnCancel' | translate}}</button>
    <button mat-button cdkFocusInitial color="primary" [disabled]="isLoading" (click)="onSaveWorkEntry($event)">{{'app.btnSave' | translate}}</button>
  </mat-dialog-actions>
</div>
