import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { AppConfig } from '@shared/app-config/app-config';
import { AppEnvironment } from '@shared/app-config/app-environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements OnInit {

  constructor(private _http: HttpClient) { }

  ngOnInit() { }

  getAppConfig() {
    let env = this._http.get(`assets/environment.json?_=${new Date().getTime()}`) as Observable<AppEnvironment>
    let config = this._http.get(`assets/app-config.json?_=${new Date().getTime()}`) as Observable<AppConfig>;

    return forkJoin([env, config]);
  }
}
