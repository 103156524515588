<mat-toolbar class="mat-elevation-z1">
  <div class="global-loading-indicator-container">
    <ng-container *ngIf="globals.isLoading">
      <mat-progress-bar class="global-loading-indicator" color="primary" mode="indeterminate"></mat-progress-bar>
    </ng-container>
  </div>
  <!-- Show Warning if Test Environment -->
  <ng-container *ngIf="globals?.appConfig?.environment != 'production'">
    <mat-toolbar-row class="test-env-warning">
      <span class="spacer"></span>
      <span class="mat-caption">{{ 'app.warning.testEnvironment' | translate }}</span>
      <span class="spacer"></span>
    </mat-toolbar-row>
  </ng-container>
  <!-- App Toolbar -->
  <mat-toolbar-row>
    <button mat-icon-button fxHide.gt-sm fxShow (click)="onToggleSidebar($event)"><mat-icon>menu</mat-icon></button>
    <!-- Logo and App Name -->
    <div class="company-info">
      <a routerLink="/home">
        <ng-container *ngIf="globals.ui.isDarkTheme; then darkThemeLogo; else lightThemeLogo"></ng-container>

        <ng-template #darkThemeLogo>
          <img fxHide fxShow.gt-sm class="logo" src="assets/logo/acx-dark.svg" />
        </ng-template>

        <ng-template #lightThemeLogo>
          <img fxHide fxShow.gt-sm class="logo" src="assets/logo/acx.svg" />
        </ng-template>
      </a>
      <button mat-button class="hr-button" routerLink="/home">
        <span class="mat-title" fxLayoutAlign="start center">HR Portal</span>
      </button>
    </div>
    <!-- Menu -->
    <button mat-button fxHide fxShow.gt-sm routerLink="/contacts">
      {{ 'app.toolbar.navEmployeeDirectory' | translate }}
    </button>
    <button
      mat-button
      fxHide
      fxShow.gt-sm
      routerLink="/work-hours"
      *ngIf="!globals.impersonate?.isAdmin() || globals.impersonate?.isImpersonating()"
    >
      {{ 'app.toolbar.navBookWorkHours' | translate }}
    </button>
    <!-- <button mat-button routerLink="/presence">Presence</button> -->
    <!-- Spacer -->
    <span class="spacer"></span>

    <!-- Start / Finish Work -->
    <ng-container
      *ngIf="!globals.impersonate?.isAdmin(); then startFinishWorkButton"
    ></ng-container>

    <ng-template #startFinishWorkButton>
      <ng-container *ngIf="hasStartedWork; then finishWorkButton; else startWorkButton"></ng-container>

      <ng-template #startWorkButton>
        <button mat-button color="primary" [disabled]="isLoading || hasLeftForToday" (click)="onStartWorkClick($event)">
          <ng-container *ngIf="hasLeftForToday; then altText; else defaultText"></ng-container>

          <ng-template #defaultText>
            <mat-icon>computer</mat-icon>&nbsp;<span>{{ 'app.toolbar.btnStartWork' | translate }}</span>
          </ng-template>

          <ng-template #altText>
            <div fxHide fxShow.gt-sm>{{ 'app.toolbar.btnAlreadyLeftLong' | translate }}</div>
            <div fxHide.gt-sm fxShow>{{ 'app.toolbar.btnAlreadyLeftShort' | translate }}</div>
          </ng-template>
        </button>
      </ng-template>

      <ng-template #finishWorkButton>
        <button
          mat-button
          matTooltip="{{ 'app.toolbar.tooltipWorkStartedAt' | translate }} {{ startTime.toString() | date: 'HH:mm' }} {{
            'app.hoursTimeUnit' | translate
          }}"
          [disabled]="isLoading || hasLeftForToday"
          (click)="onFinishWorkClick($event)"
        >
          <mat-icon>meeting_room</mat-icon>&nbsp;
          <span fxHide fxShow.gt-sm
            >{{ 'app.toolbar.btnFinishWork' | translate
            }}<ng-container *ngIf="duration">&nbsp;({{ cTime.parseDuration(duration) }})</ng-container></span
          >
          <span fxHide.gt-sm fxShow
            ><ng-container *ngIf="duration">{{ cTime.parseDuration(duration) }}</ng-container></span
          >
        </button>
      </ng-template>
    </ng-template>
    <!-- App Menu -->
    <button mat-icon-button [matMenuTriggerFor]="appMenu" fxHide fxShow.gt-sm><mat-icon>apps</mat-icon></button>
    <mat-menu #appMenu="matMenu">
      <a mat-menu-item target="_new" href="https://redmine.nsc-gmbh.de">
        <span>&nbsp;Redmine</span>
      </a>
    </mat-menu>
    <!-- More Options -->
    <ng-container *ngIf="globals.impersonate?.isImpersonating(); then warnButton; else regularButton"></ng-container>

    <ng-template #warnButton>
      <button fxHide fxShow.gt-sm color="warn" mat-raised-button [matMenuTriggerFor]="profileMenu">
        <shared-avatar [size]="avatarSize.Tiny" [status]="globals.userStatus$|async"></shared-avatar>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <button fxHide.gt-sm fxShow mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </ng-template>

    <ng-template #regularButton>
      <button fxHide fxShow.gt-sm mat-button [matMenuTriggerFor]="profileMenu">
        <shared-avatar [size]="avatarSize.Tiny" [status]="globals.userStatus$|async"></shared-avatar>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <button fxHide.gt-sm fxShow mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </ng-template>

    <mat-menu #profileMenu="matMenu">
      <ng-template matMenuContent>
        <div disabled="true" mat-menu-item fxLayout="row">
          <span class="spacer"></span>
          <shared-avatar [size]="avatarSize.Small" imageSize="35" [status]="globals.userStatus$|async"></shared-avatar>
          <span class="spacer"></span>
        </div>
        <div disabled="true" mat-menu-item fxLayout="row">
          <span class="spacer"></span>
          <span #menuTextProfileName class="avatar-menubar-name"
            >&nbsp;{{ globals.impersonate?.user.firstName }}&nbsp;{{ globals.impersonate?.user.lastName }}</span
          >
          <span class="spacer"></span>
        </div>

        <mat-divider></mat-divider>
        <button
          *ngIf="globals?.appConfig?.environment != 'production'"
          mat-menu-item
          (click)="onRemoveTokensClick($event)"
        >
          <mat-icon>no_sim</mat-icon>
          <span>&nbsp;Remove Tokens</span>
        </button>
        <button
          *ngIf="globals.impersonate?.isAdmin()"
          [disabled]="!globals.impersonate?.isImpersonating()"
          (click)="onStopImpersonateClick($event)"
          mat-menu-item
        >
          <mat-icon>assignment_ind</mat-icon>
          <span>&nbsp;{{ 'app.toolbar.moreMenu.impersonate' | translate }}</span>
        </button>
        <!-- Toggle Employee Status -->
        <div class="theme-toggle" fxLayout="column" fxLayoutAlign="center stretch" mat-menu-item>
          <mat-slide-toggle
            color="warn"
            [disabled]="!hasStartedWork || globals.impersonate?.isImpersonating()"
            [(ngModel)]="uiStatusSlideToggle"
            (ngModelChange)="onStatusSlideToggle($event)"
          >
            <span #menuTextStatusToggle>{{ 'app.toolbar.moreMenu.unavailable' | translate }}</span>
          </mat-slide-toggle>
        </div>
        <!-- Toggle Darkmode -->
        <div class="theme-toggle" fxLayout="column" fxLayoutAlign="center stretch" mat-menu-item>
          <mat-slide-toggle
            color="primary"
            [(ngModel)]="uiThemeSlideToggle"
            (ngModelChange)="onThemeSlideToggle($event)"
          >
            <span #menuTextThemeToggle>Dark Mode</span>
          </mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
        <!-- Menu Items -->
        <button mat-menu-item (click)="onProfileClick($event)">
          <mat-icon>person</mat-icon><span>&nbsp;{{ 'app.toolbar.moreMenu.profile' | translate }}</span>
        </button>
        <button mat-menu-item (click)="onLogoutClick($event)">
          <mat-icon></mat-icon><span>&nbsp;{{ 'app.toolbar.moreMenu.logout' | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onOpenInfoDialog($event)">
          <mat-icon>info</mat-icon><span>&nbsp;{{ 'app.toolbar.moreMenu.appInfo' | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
