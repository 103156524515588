import { EmployeeContact } from '@shared/api/employee-contact.interface';
import { Phone } from '@shared/api/phone.interface';
import { Token } from '@shared/api/token';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';

export class Impersonate {
  private readonly _userKey: string;
  private _admin: EmployeeContact;

  get admin(): EmployeeContact {
    return this._admin;
  }
  get user(): EmployeeContact {
    return JSON.parse(sessionStorage.getItem(this._userKey));
  }

  constructor(token: Token, userKey: string) {
    this._userKey = userKey;
    this.parseToken(token);
  }

  private parseToken(token: Token) {
    if (token && token.tokenData) {
      const employee = {
        id: token.tokenData.identifier,
        firstName: token.tokenData.userGivenname,
        lastName: token.tokenData.userSurname,
        phones: new Array<Phone>(),
        email: token.tokenData.userEmail,
        isAtWork: false,
        doB: new Date(0),
        status: { text: AvatarStatus.Offline, changed: new Date(Date.now()) },
      };

      if (token.tokenData.canImpersonate) {
        this._admin = Object.assign({}, employee);
      }

      this.setUser(employee, this._userKey);
    }
  }

  isAdmin(): boolean {
    return this.admin ? true : false;
  }

  isImpersonating(): boolean {
    if (this.isAdmin()) {
      // if admin.id and user.id are the same then the admin does not impersonate someone else
      if (this.admin.id === this.user.id) {
        return false;
      } else {
        return true;
      }
    }

    // if admin object isn't set at all then the user doesn't impersonate
    return false;
  }

  setUser(employee: EmployeeContact, key: string) {
    sessionStorage.setItem(key, JSON.stringify(Object.assign({}, employee)));
  }

  stopImpersonate() {
    if (this.isImpersonating()) {
      sessionStorage.removeItem(this._userKey);
      this.setUser(this._admin, this._userKey);
    }
  }
}
