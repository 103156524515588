<app-toolbar (hasStartedWorkEvent)="handleHasStartedWorkEvent()" (hasFinishedWorkEvent)="handleHasFinishedWorkEvent()"></app-toolbar>
<div class="toolbar-content-spacer"></div>
<div *ngIf="globals?.appConfig?.environment!=='production'" class="toolbar-content-spacer-test"></div>
<div fxLayout="column" fxLayoutAlign="start stretch">
  <!-- Date Picker -->
  <div class="workhours-info-bar mat-typography" >
      <div class="stats-container" fxLayout="column">
        <!-- Desktop View -->
        <ng-container>
          <div fxHide fxShow.gt-sm fxLayout="column" fxLayout.gt-sm="row">
            <div fxLayout="row" fxFlex.gt-sm="50">
              <span class="mat-subheading-1" fxFlex="70" fxFlex.gt-sm="50">{{'app.pages.workHours.worktimeWeekly' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" fxFlex.gt-sm="50" *ngIf="weeklyWorktime">{{weeklyWorktime?.toString('hh:mm')}} h</span>
            </div>
            <div fxLayout="row" fxFlex.gt-sm="50">
              <span class="mat-subheading-1" fxFlex="70" fxFlex.gt-sm="50">{{'app.pages.workHours.presenceTodayBegin' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" fxFlex.gt-sm="50">
                <ng-container *ngIf="presenceToday?.begin && presenceToday?.begin !== globals.localeSettings.nullDate">
                {{presenceToday?.begin | date: 'HH:mm'}}&nbsp;{{'app.hoursTimeUnit' | translate}}
                </ng-container>
              </span>
            </div>
          </div>
          <div fxHide fxShow.gt-sm fxLayout="column" fxLayout.gt-sm="row">
            <div fxLayout="row" fxFlex.gt-sm="50">
              <span class="mat-subheading-1" fxFlex="70" fxFlex.gt-sm="50">{{'app.pages.workHours.worktimeDaily' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" fxFlex.gt-sm="50" *ngIf="dailyWorktime">{{dailyWorktime?.toString('hh:mm')}} h</span>
            </div>
            <div fxLayout="row" fxFlex.gt-sm="50">
              <span class="mat-subheading-1" fxFlex="70" fxFlex.gt-sm="50">{{'app.pages.workHours.presenceTodayEnd' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" fxFlex.gt-sm="50">
                  <ng-container *ngIf="presenceToday?.end && presenceToday?.end !== globals.localeSettings.nullDate">
                    {{presenceToday?.end | date: 'HH:mm'}}&nbsp;{{'app.hoursTimeUnit' | translate}}
                  </ng-container>
              </span>
            </div>
          </div>
          <!-- Mobile View -->
          <div fxHide.gt-sm fxShow>
            <div fxLayout="row">
              <span class="mat-subheading-1" fxFlex="70" >{{'app.pages.workHours.presenceTodayBegin' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" >
                <ng-container *ngIf="presenceToday?.begin && presenceToday?.begin !== globals.localeSettings.nullDate">
                {{presenceToday?.begin | date: 'HH:mm'}}&nbsp;{{'app.hoursTimeUnit' | translate}}
                </ng-container>
              </span>
            </div>
            <div fxLayout="row">
              <span class="mat-subheading-1" fxFlex="70" >{{'app.pages.workHours.presenceTodayEnd' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" >
                  <ng-container *ngIf="presenceToday?.end && presenceToday?.end !== globals.localeSettings.nullDate">
                    {{presenceToday?.end | date: 'HH:mm'}}&nbsp;{{'app.hoursTimeUnit' | translate}}
                  </ng-container>
              </span>
            </div>
            <div fxLayout="row">
              <span class="mat-subheading-1" fxFlex="70" >{{'app.pages.workHours.worktimeWeekly' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" *ngIf="weeklyWorktime">{{weeklyWorktime?.toString('hh:mm')}} h</span>
            </div>
            <div fxLayout="row">
              <span class="mat-subheading-1" fxFlex="70" >{{'app.pages.workHours.worktimeDaily' | translate}}</span>
              <span class="mat-subheading-1" fxFlex="30" *ngIf="dailyWorktime">{{dailyWorktime?.toString('hh:mm')}} h</span>
            </div>
          </div>
        </ng-container>
        <mat-divider></mat-divider>
      </div>
  </div>

  <!-- Date Picker -->
  <div class="workhours-datepicker-sm" fxShow fxHide.gt-sm fxLayout="row" fxLayoutAlign="">
    <mat-form-field>
      <input matInput [formControl]="selectedDateFormControl" [matDatepicker]="picker" placeholder="{{'app.pages.workHours.placeholderChooseDate' | translate}}" (dateChange)="setDate($event, true)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker weekStartsOn="1" touchUi="true" #picker></mat-datepicker>
    </mat-form-field>
    <button class="icon-button-row" mat-icon-button (click)="setDate()" matTooltip="{{'app.pages.workHours.btnToday' | translate}}"><mat-icon>today</mat-icon></button>
    <button class="icon-button-row" mat-icon-button (click)="expandAll = !expandAll" matTooltip="{{'app.pages.workHours.btnExpandAllLong' | translate}}"><mat-icon>view_agenda</mat-icon></button>
  </div>

  <!-- Content -->
  <div class="workhours-content-container">
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <!-- Mini Calendar -->
      <div class="workhours-content-calendar" fxShow.gt-sm fxHide fxFlex="21" fxLayout="column">
        <mat-calendar #miniCalendar weekStartsOn="1" [selected]="selectedDate" (selectedChange)="setDate($event)"></mat-calendar>
        <button mat-button (click)="setDate()">{{'app.pages.workHours.btnToday' | translate}}</button>
        <button mat-button (click)="expandAll = !expandAll">{{'app.pages.workHours.btnExpandAllLong' | translate}}</button>
      </div> <!-- ./Mini Calendar -->

      <!-- Work Hour Entries -->
      <div class="work-entries" fxFlex="grow">
          <ng-container *ngIf="globals.sharedEntries">
              <mat-expansion-panel *ngFor="let entry of globals.sharedEntries" [expanded]="expandAll" hideToggle="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span fxLayoutAlign="start center">
                        <!-- Colored dot -->
                        <ng-container *ngIf="globals.entryDefinition?.mapColor(entry.type); then showIcon else showPlaceholder"></ng-container>
                        <ng-template #showIcon><mat-icon [ngStyle]="{'color':globals.entryDefinition?.mapColor(entry.type)}">fiber_manual_record</mat-icon>&nbsp;&nbsp;</ng-template>
                        <ng-template #showPlaceholder><mat-icon></mat-icon>&nbsp;&nbsp;</ng-template>
                        <!-- Header Text -->
                        <ng-container *ngIf="!isAllDayAbsence(entry) && !isPublicHoliday(entry); then dateAndTime else altHeadline"></ng-container>
                        <ng-template #dateAndTime>{{entry.begin | date: 'HH:mm'}} - {{entry.end | date: 'HH:mm'}}</ng-template>
                        <ng-template #altHeadline>{{'app.pages.workHours.entry.allDay' | translate}}</ng-template>
                      </span>
                    </mat-panel-title>
                    <mat-panel-description fxHide fxShow.gt-sm>
                      <span fxLayoutAlign="start center">
                        {{entry.description | slice: 0:50}}
                        <ng-container *ngIf="entry.description.length > 50">...</ng-container>
                      </span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div fxLayout="column">
                    <!-- Panel Content -->
                    <div fxLayout="column" fxLayout.gt-sm="row">

                      <!-- From Date / Time Details -->
                      <ng-container *ngIf="isAllDayAbsence(entry); then shortStartDateView else longStartDateView"></ng-container>

                      <ng-template #shortStartDateView>
                        <mat-form-field class="form-field-detail">
                          <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.from' | translate}}" value="{{entry.begin | date: 'dd. MMMM yyyy'}}">
                        </mat-form-field>
                      </ng-template>

                      <ng-template #longStartDateView>
                        <mat-form-field class="form-field-detail">
                          <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.from' | translate}}" value="{{entry.begin | date: 'dd. MMMM yyyy - HH:mm'}}">
                        </mat-form-field>
                      </ng-template>
                      
                      <!-- To Date / Time Details -->
                      <ng-container *ngIf="isAllDayAbsence(entry); then shortEndDateView else longEndDateView"></ng-container>

                      <ng-template #shortEndDateView>
                        <mat-form-field class="form-field-detail">
                          <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.to' | translate}}" value="{{entry.end | date: 'dd. MMMM yyyy'}}">
                        </mat-form-field>
                      </ng-template>

                      <ng-template #longEndDateView>
                        <mat-form-field class="form-field-detail">
                          <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.to' | translate}}" value="{{entry.end | date: 'dd. MMMM yyyy - HH:mm'}}">
                        </mat-form-field>
                      </ng-template>
                    </div>
                    <div *ngFor="let ticket of entry.ticketReferences">
                      <mat-form-field class="form-field-detail">
                        <!-- <span matPrefix>#&nbsp;</span> -->
                        <input matInput placeholder="{{'app.pages.workHours.entry.issueId' | translate}}" disabled="true" value="{{ticket.identification}}">
                      </mat-form-field>
                    </div>
                    <div fxLayout="column" fxLayout.gt-sm="row">
                      <mat-form-field class="form-field-detail" *ngIf="!isTimeBasedAbsence(entry) && !isAllDayAbsence(entry)">
                        <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.type' | translate}}" value="{{entry.type}}">
                      </mat-form-field>
                      <mat-form-field class="form-field-detail" *ngIf="!isTimeBasedAbsence(entry) && !isAllDayAbsence(entry)">
                        <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.activity' | translate}}" value="{{entry.activity}}">
                      </mat-form-field>
                    </div>
                    <mat-form-field class="form-field-detail">
                        <input matInput disabled="true" placeholder="{{'app.pages.workHours.entry.description' | translate}}" value="{{entry.description}}">
                    </mat-form-field>
                    <!-- Panel Actions -->
                    <div fxLayout="row">
                      <div fxHide fxShow.gt-sm class="spacer"></div>
                      <button mat-icon-button (click)="onEditWorkEntryDialog(entry)" *ngIf="!isTimeBasedAbsence(entry) && !isAllDayAbsence(entry) && !isPublicHoliday(entry)"><mat-icon>edit</mat-icon></button>
                      <button mat-icon-button color="warn" (click)="onDeleteWorkEntry(entry)" *ngIf="!isPublicHoliday(entry)"><mat-icon>close</mat-icon></button>
                    </div>
                  </div>
              </mat-expansion-panel>
            </ng-container>
      </div> <!-- ./Work Hour Entries -->

      <!-- Spacer -->
      <div fxFlex="8" fxShow.gt-sm fxHide>
      </div> <!-- ./Spacer -->
    </div>
  </div>
</div>
<!-- Quick Actions -->
<div *ngIf="!globals.impersonate?.isAdmin() || globals.impersonate?.isImpersonating()" class="floating-fab-container" (mouseleave)="onNewWorkEntryLeave($event)" >
  <button #fabQuickAction1 [style.visibility]="getQuickActionVisibility()" (click)="onNewAllDayBasedAbsenceDialog(selectedDate)" class="floating-fab-2" mat-fab [disabled]="disableQuickActions" matTooltip="{{'app.pages.workHours.tooltipAllDayAbsence' | translate}}" matTooltipPosition="left"><mat-icon>calendar_today</mat-icon></button>
  <button #fabQuickAction1 [style.visibility]="getQuickActionVisibility()" (click)="onNewTimeBasedAbsenceDialog(selectedDate)" class="floating-fab-1" mat-fab [disabled]="disableQuickActions" matTooltip="{{'app.pages.workHours.tooltipTimeBasedAbsence' | translate}}" matTooltipPosition="left"><mat-icon>alarm</mat-icon></button>
  <button class="floating-fab" mat-fab color="primary" (click)="onNewWorkEntryDialog(selectedDate)" (mouseover)="onNewWorkEntryHover($event)" matTooltip="{{'app.pages.workHours.tooltipNewEntry' | translate}}" matTooltipPosition="left"><mat-icon>add</mat-icon></button>
</div>
