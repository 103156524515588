import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// auth guard
import { AuthGuardService as AuthGuard } from '@services/authentication/auth-guard.service';

// pages
import { HomeComponent } from '@components/pages/home/home.component';
import { LoginComponent } from '@components/pages/login/login.component';
import { ProfileComponent } from '@components/pages/profile/profile.component';
import { EmployeesComponent } from '@components/pages/employees/employees.component';
import { BookWorkHoursComponent } from '@components/pages/book-work-hours/book-work-hours.component';
import { LoginForgotComponent } from '@components/pages/login-forgot/login-forgot.component';
import { LoginRecoverComponent } from '@components/pages/login-recover/login-recover.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'employees', component: EmployeesComponent, canActivate: [AuthGuard] },
  { path: 'contacts', component: EmployeesComponent, canActivate: [AuthGuard] },
  { path: 'work-hours', component: BookWorkHoursComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'login-reset', component: LoginForgotComponent },
  { path: 'login-recover', component: LoginRecoverComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
