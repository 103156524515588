<div mat-dialog-container class="app-dialog-info action-row-bottom-fix mat-typography" fxLayout="column" fxLayoutAlign="space-evenly left" fxFlex="grow">
  <!-- header -->
  <h2 mat-dialog-title>{{globals.appConfig?.app.viewName}}&nbsp;{{'app.dialog.infoTitle' | translate}}</h2>
  <p>
    <span>{{globals.appConfig?.app.copyright}}</span>
  </p>
  <!-- content -->
  <div>
    <mat-dialog-content class="mat-typography">
      <mat-tab-group>
        <mat-tab label="Version">
          <mat-list role="list">
            <mat-list-item *ngIf="globals" role="listitem">UI {{'app.dialog.infoVersion' | translate}} {{globals.appConfig?.version}}</mat-list-item>
            <mat-list-item *ngIf="apiInfo" role="listitem">API {{'app.dialog.infoVersion' | translate}} v{{apiInfo?.apiVersion}}</mat-list-item>
            <mat-list-item>{{'app.dialog.infoFoundBug' | translate}}&nbsp;<a mat-button target="_new" href="https://git.nsc-gmbh.de/hr-portal/ui/issues">{{'app.dialog.infoReportIt' | translate}}</a></mat-list-item>
          </mat-list>
        </mat-tab>

        <mat-tab label="UI Changelog">
          <markdown class="changelog" [src]="getChangelogUrl()"></markdown>
        </mat-tab>

        <mat-tab label="Shortcuts">
          <markdown class="changelog" [src]="getShortcutsUrl()"></markdown>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </div>
  <!-- action -->
  <div class="spacer"></div>
  <div>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{'app.btnClose' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
