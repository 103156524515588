<mat-progress-bar color="primary" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div mat-dialog-container class="app-change-passwd-dialog mat-typography action-row-bottom-fix" fxLayout="column" fxFlex="grow">
  <form #profileChangePasswordForm (ngSubmit)="onSaveNewPasswordClick($event)" fxLayout="column" fxLayoutAlign="space-evenly left" fxFlex="grow">
    <!-- header -->
    <h2 mat-dialog-title>{{'app.dialog.changePwdTitle' | translate}}</h2>
    <!-- content -->
    <div>
      <mat-dialog-content class="mat-typography">
      <div class="dialog-message">
        <mat-form-field>
          <input matInput 
                cdkInitialFocus
                type="password"
                required
                placeholder="{{'app.dialog.changePwdCurrentPwd'|translate}}" 
                [formControl]="formControlCurrentPassword"/>
          <mat-error *ngIf="formControlCurrentPassword.invalid">{{getCurrentPwdErrors()}}</mat-error>
        </mat-form-field>
        <div class="change-pwd-message">{{'app.dialog.changePwdStrengthHint'|translate}}</div>
        <mat-form-field>
          <input matInput
                type="password"
                required
                placeholder="{{'app.dialog.changePwdNewPwd'|translate}}"
                [formControl]="formControlNewPassword"
                [pattern]="patternNewPassword"/>
          <mat-error *ngIf="formControlNewPassword.invalid">{{getNewPwdErrors()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                type="password"
                required
                placeholder="{{'app.dialog.changePwdConfirmPwd'|translate}}"
                [formControl]="formControlConfirmPassword"
                [pattern]="patternNewPassword"/>
          <mat-error *ngIf="formControlConfirmPassword.invalid">{{getConfirmPwdErrors()}}</mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    </div>
    <!-- action -->
    <div class="spacer"></div>
    <div>
      <mat-divider></mat-divider>
      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button" [disabled]="isLoading">{{'app.btnCancel' | translate}}</button>
        <button mat-button color="primary" type="submit" [disabled]="isLoading">{{'app.btnChangePasswd' | translate}}</button>
      </mat-dialog-actions>
    </div>
  </form>
</div>