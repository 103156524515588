export class LocalStorageKeys {
    user: string;
    bearerToken: string;
    data: LocalStorageData;
    sessionId: string;

    constructor() {
        this.data = new LocalStorageData();
    }
}

export class LocalStorageData {
    workHourEntry: string;
    allDayAbsence: string;
    timeBasedAbsence: string;
    uiTheme: string;

    constructor() { }
}
