import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ApiService } from '@services/api/api.service';
import { ChangePassword } from '@shared/api/change-password.interface';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@services/user-info.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {
  dialogData: any;
  isLoading = false;

  // error translations
  errorMsgInvalidCurrentPwd: string;
  errorMsgInvalidLogin: string;
  errorMsgRequiredField: string;
  errorMsgInvalidPwdPattern: string;
  errorMsgPasswordsDontMatch: string;
  errorMsgPasswordsMatch: string;
  errorMsgGenericError: string;
  msgChangedSuccessfully: string;

  // formControls
  formControlCurrentPassword = new FormControl('', [
    Validators.required
  ]);

  patternNewPassword     = '.{8,}';
  formControlNewPassword = new FormControl('', [
    Validators.pattern(this.patternNewPassword),
    Validators.required
  ]);

  formControlConfirmPassword = new FormControl('', [
    Validators.pattern(this.patternNewPassword),
    Validators.required
  ]);

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private _translateService: TranslateService,
              private _userInfoService: UserInfoService) {
    this.dialogData = data;
  }

  ngOnInit() {
    this._translateService.get('app.dialog.changePwdCurrentPwdError')
      .subscribe(translation => this.errorMsgInvalidCurrentPwd = translation);

    this._translateService.get('app.dialog.changePwdPatternError')
      .subscribe(translation => this.errorMsgInvalidPwdPattern = translation);

    this._translateService.get('app.dialog.changePwdMatchError')
      .subscribe(translation => this.errorMsgPasswordsMatch = translation);

    this._translateService.get('app.dialog.changePwdDontMatchError')
      .subscribe(translation => this.errorMsgPasswordsDontMatch = translation);

    this._translateService.get('app.dialog.changePwdRequiredError')
      .subscribe(translation => this.errorMsgRequiredField = translation);

    this._translateService.get('app.dialog.changePwdInvalidLoginError')
      .subscribe(translation => this.errorMsgInvalidLogin = translation);

    this._translateService.get('app.dialog.changePwdGenericError')
      .subscribe(translation => this.errorMsgGenericError = translation);

    this._translateService.get('app.dialog.changePwdSuccess')
      .subscribe(translation => this.msgChangedSuccessfully = translation);
  }

  onSaveNewPasswordClick(event: any) {
    if (!this.formControlCurrentPassword.invalid ||
        !this.formControlNewPassword.invalid ||
        !this.formControlConfirmPassword.invalid) {
      // error out if current and new pwd match
      if (this.formControlCurrentPassword.value === this.formControlNewPassword.value) {
        this.formControlNewPassword.setErrors({ passwordMatch: true });
      } else {
        // compare retyped password & send api request
        if (this.formControlNewPassword.value === this.formControlConfirmPassword.value) {
          const requestData: ChangePassword = {
            currentPassword: this.formControlCurrentPassword.value,
            newPassword: this.formControlNewPassword.value
          };

          this.isLoading = true;
          this._apiService.putChangePassword(requestData)
            .subscribe(
              reply => {
                this.isLoading = false;
                this._userInfoService.showInfoMessage(this.msgChangedSuccessfully, 2000);
                this.dialogRef.close(true);
              },
              error => {
                this.isLoading = false;
                if (error.status === 400) {
                  if (error.error === 'invalid credentials') {
                    this.formControlCurrentPassword.setErrors({ invalidPassword: true });
                  }
                } else {
                  this.formControlConfirmPassword.setErrors({ genericError: true });
                }
              }
          );
        } else {
          this.formControlConfirmPassword.setErrors({ notEqual: true });
        }
      }
    }
  }

  getCurrentPwdErrors(): string {
    if (this.formControlCurrentPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlCurrentPassword.hasError('pattern')) {
      return this.errorMsgInvalidPwdPattern;
    }

    if (this.formControlCurrentPassword.hasError('invalidPassword')) {
      return this.errorMsgInvalidLogin;
    }
  }

  getNewPwdErrors(): string {
    if (this.formControlNewPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlNewPassword.hasError('pattern')) {
      return this.errorMsgInvalidPwdPattern;
    }

    if (this.formControlNewPassword.hasError('passwordMatch')) {
      return this.errorMsgPasswordsMatch;
    }
  }

  getConfirmPwdErrors(): string {
    if (this.formControlConfirmPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlConfirmPassword.hasError('pattern')) {
      return this.errorMsgInvalidPwdPattern;
    }

    if (this.formControlConfirmPassword.hasError('notEqual')) {
      return this.errorMsgPasswordsDontMatch;
    }

    if (this.formControlConfirmPassword.hasError('genericError')) {
      return this.errorMsgGenericError;
    }
  }
}
