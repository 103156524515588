export class Time {
    hours: number;
    minutes: number;
    seconds: number;

    private seperator = ':';
    private isNegative = false;

    constructor () {
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
    }

    static parseDuration(milliseconds: number): string {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor(milliseconds / 60000 - hours * 60);

        // only output positive durations or zero
        let hourStr: string;
        if (hours < 0 ) {
            hourStr = '00';
        } else {
            hourStr = ('0' + hours.toString()).slice(-2);
        }

        let minStr: string;
        if (minutes < 0) {
            minStr = '00';
        } else {
            minStr = ('0' + minutes.toString()).slice(-2);
        }

        return new Array<string>(hourStr, minStr).join(':');
    }

    static parseDateTime(dateTime: Date): Time {
        const newTime = new Time();

        newTime.hours   = dateTime.getHours();
        newTime.minutes = dateTime.getMinutes();
        newTime.seconds = dateTime.getSeconds();

        return newTime;
    }

    static parseString(time: string, format: string): Time {
        const newTime = new Time();

        switch (format) {
            case 'hh':
                newTime.hours = parseInt(time, 10);
                break;
            case 'hh:mm':
                newTime.hours   = parseInt(time.slice(0, 2), 10);
                newTime.minutes = parseInt(time.slice(3, 5), 10);
                break;
            case 'hh:mm:ss':
                newTime.hours   = parseInt(time.slice(0, 2), 10);
                newTime.minutes = parseInt(time.slice(3, 5), 10);
                newTime.seconds = parseInt(time.slice(6, 8), 10);
                break;
        }

        return newTime;
    }

    /**
     * Convert a date/time expressed in a local timezone to UTC date/time.
     * @param date The local date/time
     * @returns UTC date/time
     */
    static toUtcDate(date: Date): Date {
        const utcDateTime = new Date(date.getUTCFullYear(),
                                     date.getUTCMonth(),
                                     date.getUTCDate(),
                                     date.getUTCHours(),
                                     date.getUTCMinutes(),
                                     date.getUTCSeconds());
        return utcDateTime;
    }

    /**
     * Convert a date/time expressed in a UTC timezone to local date/time.
     * @param date The UTC date/time
     * @returns Local date/time
     */
    static toLocalDate(date: Date): Date {
        const localDateTime = new Date(date.getFullYear(),
                                     date.getMonth(),
                                     date.getDate(),
                                     date.getHours(),
                                     date.getMinutes(),
                                     date.getSeconds());
        return localDateTime;
    }

    static parseNumber(number: number): Time {
        const time = new Time();
        if (number < 0) {
            number = number * -1;
            time.isNegative = true;
        }

        const decimals = number - Math.floor(number);

        time.hours = Math.floor(number);
        time.minutes = Math.round(60 * decimals);
        time.seconds = 0;

        return time;
    }

    toDate(date: Date): Date {
        const newDate = new Date(date);

        newDate.setHours(this.hours);
        newDate.setMinutes(this.minutes);
        newDate.setSeconds(this.seconds);

        return newDate;
    }

    toString(format?: string): string {
        let formatValue = '';
        if (this.isNegative) {
            formatValue = '- ';
        }

        let strHours;
        if (this.hours < 10) {
            strHours = ('0' + this.hours.toString()).slice(-2);
        } else {
            strHours = this.hours.toString();
        }

        let strMinutes;
        if (this.minutes < 10) {
            strMinutes = ('0' + this.minutes.toString()).slice(-2);
        } else {
            strMinutes = this.minutes.toString();
        }

        let strSeconds;
        if (this.seconds < 10) {
            strSeconds = ('0' + this.seconds.toString()).slice(-2);
        } else {
            strSeconds = this.seconds.toString();
        }

        if (format) {
            switch (format) {
                case 'hh':
                    formatValue = formatValue + strHours;
                    return formatValue;
                case 'hh:mm':
                    formatValue = formatValue + new Array<string>(strHours, strMinutes).join(this.seperator);
                    return formatValue;
                case 'hh:mm:ss':
                    formatValue = formatValue + new Array<string>(strHours, strMinutes, strSeconds).join(this.seperator);
                    return formatValue;
            }
        } else {
            formatValue = formatValue + new Array<string>(strHours, strMinutes, strSeconds).join(this.seperator);
            return formatValue;
        }
    }
}
