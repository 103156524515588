<div
  mat-dialog-container
  class="app-dialog-contact action-row-bottom-fix mat-typography"
  fxLayout="column"
  fxLayoutAlign="space-evenly left"
  fxFlex="grow"
>
  <!-- title -->
  <h2 mat-dialog-title></h2>
  <!-- content -->
  <div>
    <mat-dialog-content fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <shared-avatar
          [size]="avatarSize.Large"
          [gravatarEmail]="data.email"
          [status]="globals.getAvatarStatus(data)"
        ></shared-avatar>
        <ng-template #onlineAvatar
          ><div
            class="avatar-badge-large"
            matBadge="&#8204;"
            matBadgeHidden="false"
            matBadgeSize="small"
            matBadgeColor="primary"
            matBadgePosition="below after"
          >
            <img class="avatar-large" [src]="getGravatar(data.email)" /></div
        ></ng-template>
        <ng-template #offlineAvatar><img class="avatar-large" [src]="getGravatar(data.email)" /></ng-template>
        <span class="mat-title">{{ data.firstName }}&nbsp;{{ data.lastName }}</span>
      </div>
      <!-- details -->
      <span class="mat-subheading-2">{{ 'app.dialog.contactTitle' | translate }}</span>
      <mat-divider></mat-divider>
      <!-- E-Mail -->
      <div fxLayout="column" fxLayout.gt-sm="row" class="mat-subheading-1 contact-data-row">
        <span fxFlex="45">{{ 'app.pages.profile.detailsEmail' | translate }}</span>
        <span>{{ data?.email }}</span>
      </div>
      <!-- Phone(s) -->
      <ng-container *ngFor="let phone of sortedPhoneList">
        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutAlign="start start"
          class="mat-subheading-1 contact-data-row"
        >
          <span fxFlex="100" fxFlex.gt-sm="45">{{ getPhoneTypeViewValue(phone) }}</span>
          <a mat-button href="tel:{{ phone.number }}">{{ phone.number | phone }}</a>
        </div>
      </ng-container>
    </mat-dialog-content>
  </div>
  <!-- action -->
  <div class="spacer"></div>
  <div>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ 'app.btnClose' | translate }}</button>
    </mat-dialog-actions>
  </div>
</div>
