import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { DeDateAdapter } from '@shared/locale-settings/de-date-adapter';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

// pipes
import { PhoneValuePipe } from '@shared/pipes/phone.pipe';
import { EnumKeyValueListPipe } from '@shared/pipes/enumlist.pipe';

// ui
import { ToolbarComponent } from '@components/ui/toolbar/toolbar.component';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { AvatarComponent } from './components/ui/avatar/avatar.component';

// dialogs
import { WorkHourEntryDialogComponent } from '@components/dialogs/work-hour-entry-dialog/work-hour-entry-dialog.component';
import { StatsDetailedInfoDialogComponent } from '@components/dialogs/stats-detailed-info-dialog/stats-detailed-info-dialog.component';
import { AppInfoDialogComponent } from '@components/dialogs/app-info-dialog/app-info-dialog.component';
import { AllDayAbsenceDialogComponent } from '@components/dialogs/all-day-absence-dialog/all-day-absence-dialog.component';
import { TimeBasedAbsenceDialogComponent } from '@components/dialogs/time-based-absence-dialog/time-based-absence-dialog.component';
import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';
// tslint:disable-next-line:max-line-length
import { StatsDetailedInfoOvertimeDialogComponent } from '@components/dialogs/stats-detailed-info-overtime-dialog/stats-detailed-info-overtime-dialog.component';
import { ContactDetailDialogComponent } from '@components/dialogs/contact-detail-dialog/contact-detail-dialog.component';
import { ChangePasswordDialogComponent } from '@components/dialogs/change-password-dialog/change-password-dialog.component';

// pages
import { LoginComponent } from '@components/pages/login/login.component';
import { LoginForgotComponent } from '@components/pages/login-forgot/login-forgot.component';
import { LoginRecoverComponent } from '@components/pages/login-recover/login-recover.component';
import { HomeComponent } from '@components/pages/home/home.component';
import { ProfileComponent } from '@components/pages/profile/profile.component';
import { PresenceComponent } from '@components/pages/presence/presence.component';
import { EmployeesComponent } from '@components/pages/employees/employees.component';
import { BookWorkHoursComponent } from '@components/pages/book-work-hours/book-work-hours.component';

// services
import { Globals } from '@shared/globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@services/authentication/auth.service';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { AppConfigService } from '@services/app-config.service';
import { UserInfoService } from '@services/user-info.service';
import { GravatarService } from '@services/gravatar/gravatar.service';

// ngx-translate and http-loader
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SupportedLocales } from '@shared/locale-settings/supported-locales.enum';
import { SupportedLanguages } from '@shared/locale-settings/supported-languages.enum';
import { BirthdayListComponent } from './components/ui/birthday-list/birthday-list.component';
import { BirthdayListItemComponent } from './components/ui/birthday-list-item/birthday-list-item.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ToolbarComponent,
    ProfileComponent,
    PresenceComponent,
    EmployeesComponent,
    BookWorkHoursComponent,
    WorkHourEntryDialogComponent,
    EnumKeyValueListPipe,
    PhoneValuePipe,
    StatsDetailedInfoDialogComponent,
    AppInfoDialogComponent,
    AllDayAbsenceDialogComponent,
    TimeBasedAbsenceDialogComponent,
    LoginForgotComponent,
    ConfirmDialogComponent,
    StatsDetailedInfoOvertimeDialogComponent,
    ContactDetailDialogComponent,
    ChangePasswordDialogComponent,
    LoginRecoverComponent,
    BirthdayListComponent,
    BirthdayListItemComponent,
    AvatarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    Globals,
    AppConfigService,
    JwtHelperService,
    AuthService,
    AuthGuardService,
    UserInfoService,
    GravatarService,
    MarkdownService,
    {provide: DateAdapter, useClass: DeDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: SupportedLocales.German},
    {provide: LOCALE_ID, useValue: SupportedLanguages.German}
  ],
  entryComponents: [
    WorkHourEntryDialogComponent,
    StatsDetailedInfoDialogComponent,
    StatsDetailedInfoOvertimeDialogComponent,
    ContactDetailDialogComponent,
    AppInfoDialogComponent,
    TimeBasedAbsenceDialogComponent,
    AllDayAbsenceDialogComponent,
    ConfirmDialogComponent,
    ChangePasswordDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// ngx-translate - required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?_=${new Date().getTime()}`);
}
