import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '@services/authentication/auth.service';
import { Router } from '@angular/router';
import { Globals } from '@shared/globals';
import { AppDialogDataWorkEntry } from '@shared/app-dialog-data-work-entry.interface';
import { Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Token } from '@shared/api/token';
import { Phone } from '@shared/api/phone.interface';
import { Impersonate } from '@shared/global-settings/impersonate';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  visibilityToggle = false;
  isLoading = false;
  globals: Globals;

  // errors
  errorMsgInvalidLogin: string;
  errorMsgRequiredField: string;

  // form controls
  formControlUser = new FormControl('', [
    Validators.required
  ]);
  formControlPassword = new FormControl('', [
    Validators.required
  ]);

  constructor(public router: Router,
              private _translateService: TranslateService,
              private _authService: AuthService,
              private _globals: Globals) {
  }

  ngOnInit() {
    this.globals = this._globals;

    this._translateService.get('app.pages.login.invalidLoginDetails')
      .subscribe(translation => this.errorMsgInvalidLogin = translation);

    this._translateService.get('app.dialog.changePwdRequiredError')
      .subscribe(translation => this.errorMsgRequiredField = translation);
  }

  onSubmitLoginJwt(event?: any) {
    // check if user and pwd fields are validated ok
    if (!this.formControlUser.invalid && !this.formControlPassword.invalid) {
      this.isLoading = true;
      this._authService.authenticateJwt(this.formControlUser.value, this.formControlPassword.value).subscribe(
        response => {
          const token = (response as any).token;
          if (token) {
            localStorage.setItem(this._globals.localStorageKeys.bearerToken, token);
            localStorage.setItem(this._globals.localStorageKeys.user, this.formControlUser.value);

            const sessionId = Date.now().toString();
            localStorage.setItem(this._globals.localStorageKeys.sessionId, sessionId);
            sessionStorage.setItem(this._globals.localStorageKeys.sessionId, sessionId);

            // get token if it exists
            this._globals.token = new Token(localStorage.getItem(this._globals.localStorageKeys.bearerToken));
            if (this._globals.token) {
              this._globals.impersonate = new Impersonate(this._globals.token, this._globals.localStorageKeys.user);
              if (this._globals.impersonate.isAdmin()) {
                // admin users go directly to /contatcs
                this.router.navigate(['/contacts']);
              } else {
                // handle if regular user has been logged out while editing work entry
                const localStorageObject = sessionStorage.getItem(this._globals.localStorageKeys.data.workHourEntry);

                let dialogDataWorkEntry: AppDialogDataWorkEntry;
                dialogDataWorkEntry = JSON.parse(localStorageObject) as AppDialogDataWorkEntry;

                if (dialogDataWorkEntry) {
                  this._globals.sessionStorageData.workHourEntry = dialogDataWorkEntry;
                  this.isLoading = false;
                  this.router.navigate(['work-hours']);
                } else {
                  // use default route if nothing was loaded
                  this.isLoading = false;
                  this.router.navigate(['home']);
                }
              }
            }
          }
        },
        error => {
          this.isLoading = false;
          this.formControlPassword.setErrors({ invalidLogin: true });
        }
      );
    }
  }

  getUsernameErrors(): string {
    if (this.formControlUser.hasError('required')) {
      return this.errorMsgRequiredField;
    }
  }

  getPasswordErrors(): string {
    if (this.formControlPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlPassword.hasError('invalidLogin')) {
      return this.errorMsgInvalidLogin;
    }
  }
}
