import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '@shared/confirm-dialog-data.interface';
import { ConfirmDialogOptions } from '@shared/confirm-dialog-options.enum';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  dialogData: ConfirmDialogData;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.dialogData = data;
  }

  ngOnInit() { }

  /**
   * Check if a given dialog option is a positive confirmation or cancel option.
   * @param option The dialog option to be analyzed.
   * @returns True if its a confirmation option.
   */
  isConfirmationAction(option: string): boolean {
    if (option === ConfirmDialogOptions.Yes) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Close the dialog passing true. This means the user positively confirmed the dialog.
   */
  onConfirmAction() {
    this.dialogRef.close(true);
  }

}
