import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from '@environments/environment';

// services
import { ApiService } from '@services/api/api.service';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { UserInfoService } from '@services/user-info.service';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '@shared/globals';

import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { WorkHourEntryDialogComponent } from '@components/dialogs/work-hour-entry-dialog/work-hour-entry-dialog.component';
import { AppDialogDataWorkEntry } from '@shared/app-dialog-data-work-entry.interface';
import { TimeBasedAbsenceDialogComponent } from '@components/dialogs/time-based-absence-dialog/time-based-absence-dialog.component';
import { Time } from '@shared/time';
import { AllDayAbsenceDialogComponent } from '@components/dialogs/all-day-absence-dialog/all-day-absence-dialog.component';
import { PresenceEntry, Presence } from '@shared/api/presence.interface';
import { StatsFiltered } from '@shared/api/stats-filtered.interface';
import { ConfirmDialogData } from '@shared/confirm-dialog-data.interface';
import { ConfirmDialogOptions } from '@shared/confirm-dialog-options.enum';
import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-book-work-hours',
  templateUrl: './book-work-hours.component.html',
  styleUrls: ['./book-work-hours.component.css'],
  animations: [
    trigger('flyOut', [
      transition('* => void', [
        animate('0.4s 0.2s ease-out', style({
          opacity: 0,
          transform: 'translateX(100%)'
        }))
      ])
    ])
  ]
})
export class BookWorkHoursComponent implements OnInit, AfterViewInit {
  environment = environment;
  selectedDate = new Date(Date.now());
  selectedDateFormControl = new FormControl(this.selectedDate);
  globals: Globals;

  weeklyWorktime: Time;
  dailyWorktime: Time;

  // ui
  expandAll = false;
  disableQuickActions = true;

  // presence today
  presenceToday: PresenceEntry;

  // translations
  messageEntryCreated: string;
  messageAbsenceBooked: string;
  messageCantEditEntry: string;
  messageConfirmDeleteEntry: string;

  @ViewChild('miniCalendar', { static: true }) miniCalendar: MatCalendar<Date>;

  @HostListener('document:click', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.onNewWorkEntryLeave(event);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // key [N] for new entry
    if (event.keyCode === 78 && this.globals.hotkeyEnabled) {
      this.onNewWorkEntryDialog(this.selectedDate);
    }
    // key [T] for new time based entry
    if (event.keyCode === 84 && this.globals.hotkeyEnabled) {
      this.onNewTimeBasedAbsenceDialog(this.selectedDate);
    }
    // key [A] for new all day absence entry
    if (event.keyCode === 65 && this.globals.hotkeyEnabled) {
      this.onNewAllDayBasedAbsenceDialog(this.selectedDate);
    }
  }

  constructor(public workEntryDialog: MatDialog,
              public timeBasedAbsenceDialog: MatDialog,
              public allDayAbsenceDialog: MatDialog,
              public router: Router,
              private _apiService: ApiService,
              private _authGuardService: AuthGuardService,
              private _globals: Globals,
              private _userInfoService: UserInfoService,
              private _translateService: TranslateService,
              private _confirmDialog: MatDialog) {

    // translations
    this._translateService.get('app.message.absenceBooked')
      .subscribe(text => this.messageAbsenceBooked = text);

    this._translateService.get('app.message.entryCreated')
      .subscribe(text => this.messageEntryCreated = text);

    this._translateService.get('app.message.editEntryNotAllowed')
      .subscribe(text => this.messageCantEditEntry = text);

    this._translateService.get('app.message.confirmDeleteEntry')
      .subscribe(text => this.messageConfirmDeleteEntry = text);

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.ngRouteActivate(event as NavigationEnd);
        }
      }
    );

    this.globals = _globals;
  }

  ngOnInit() {
    // if resuming from edit (after login)
    if (this._globals.sessionStorageData.workHourEntry) {
      const entry = this._globals.sessionStorageData.workHourEntry as AppDialogDataWorkEntry;

      // clean up local storage
      localStorage.removeItem(this._globals.localStorageKeys.data.workHourEntry);
      this._globals.sessionStorageData.workHourEntry = undefined;

      if (entry.edit === true) {
        setTimeout(() => this.onEditWorkEntryDialog(entry.content), 0);
      } else {
        setTimeout(() => this.onNewWorkEntryDialog(entry.date, entry.content), 0);
      }

      this.setDate(entry.date);
    } else {
      this.getBookedWorkHours(this.selectedDate);
      this.getWeeklyStats(this.selectedDate);
      this.getDailyStats(this.selectedDate);
      this.getTodaysPresence(this.selectedDate);
    }
  }

  ngAfterViewInit() { }

  ngRouteActivate(event: NavigationEnd) {
    if (event.url === '/work-hours') {
      const today = new Date(Date.now());
      this.setDate(today);
    }
  }

  setDate(event?: any, isFromPicker: boolean = false) {
    // reset presenceToday
    this.presenceToday = undefined;
    this._globals.sharedEntries = new Array<WorkHourEntry>();

    // set date
    let date: Date;

    if (event) {
      if (isFromPicker) {
        date = (event as MatDatepickerInputEvent<Date>).value;
      } else {
        date = event as Date;
      }
    } else {
      date = new Date(Date.now());
      this.miniCalendar._goToDateInView(date, 'month');
    }

    this.selectedDate = date;
    this.selectedDateFormControl = new FormControl(date);

    this.getBookedWorkHours(this.selectedDate);
    this.getTodaysPresence(this.selectedDate);
    this.getDailyStats(this.selectedDate);
    this.getWeeklyStats(this.selectedDate);
  }

  getTodaysPresence(date: Date) {
    date = new Date(date);
    this._apiService.getPresence(date, date, date)
      .subscribe(
        presenceList => {
          const list: Presence = { presence: presenceList as PresenceEntry[] };

          for (const presence of list.presence) {
            const presenceDate = new Date(presence.begin);
            if ((presenceDate.getFullYear() === date.getFullYear()) &&
                (presenceDate.getMonth() === date.getMonth()) &&
                (presenceDate.getDate() === date.getDate())) {
              this.presenceToday = presence;
              return;
            }
          }
        }
      );
  }

  getWeeklyStats(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0);
    const lastMonday = new Date(date);

    let dayOfTheWeek = lastMonday.getDay() - 1;
    if (dayOfTheWeek < 0) {
      dayOfTheWeek = 6;
    }
    lastMonday.setDate(lastMonday.getDate() - dayOfTheWeek);

    const nextMonday = new Date(lastMonday);
    nextMonday.setDate(nextMonday.getDate() + 7);

    this._apiService.getStats(lastMonday, nextMonday)
      .subscribe(
        data => {
          const worktime = (data as StatsFiltered).workHours;
          this.weeklyWorktime = new Time();
          this.weeklyWorktime = Time.parseNumber(worktime);
        },
        error => {
          if ((error as HttpErrorResponse).status === 401) {
            console.log('Unauthorized access please login again.');
            this._authGuardService.logout();
          }
          console.warn(error);
        }
      );
  }

  getDailyStats(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0);
    const fromDate = new Date(date);
    const toDate = new Date(date);
    toDate.setDate(toDate.getDate() + 1);

    this._apiService.getStats(fromDate, toDate)
      .subscribe(
        data => {
          const worktime = (data as StatsFiltered).workHours;
          this.dailyWorktime = new Time();
          this.dailyWorktime = Time.parseNumber(worktime);
        },
        error => {
          if ((error as HttpErrorResponse).status === 401) {
            console.log('Unauthorized access please login again.');
            this._authGuardService.logout();
          }
          console.warn(error);
        }
      );
  }

  getBookedWorkHours(date?: Date) {
    this._apiService.getWorkHourEntries(date, date, date)
      .subscribe(
        entries => {
          this._globals.sharedEntries = new Array<WorkHourEntry>();
          this._globals.sharedEntries = entries as Array<WorkHourEntry>;
          this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());
        },
        error => {
          if ((error as HttpErrorResponse).status === 401) {
            console.log('Unauthorized access please login again.');
            this._authGuardService.logout();
          }
        }
      );
  }

  onDeleteWorkEntry(entry: WorkHourEntry) {
    const dialogData: ConfirmDialogData = {
      message: this.messageConfirmDeleteEntry,
      options: [ConfirmDialogOptions.No, ConfirmDialogOptions.Yes]
    };

    const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: 'app-dialog-style'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._apiService.deleteWorkHourEntry(entry)
        .subscribe(
          reply => {
            for (let i = 0; i < this._globals.sharedEntries.length; i++) {
              // get current item
              const item = this._globals.sharedEntries[i];
              if (item && item.id === entry.id) {
                // get entry date
                const entryDate = new Date(item.begin);

                // sort and splice entry list
                this._globals.sharedEntries.splice(i, 1);
                this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());

                // update stats
                this.updateStats(entryDate);
              }
            }
          },
          error => {
            this.handleHttpResponseNotAllowed(error);
            console.warn(error);
          }
        );
      }
    });
  }

  onEditWorkEntryDialog(entry: WorkHourEntry) {
    // ensure hotkeys are disabled while editing
    this.globals.hotkeyEnabled = false;

    const dialogRef = this.workEntryDialog.open(WorkHourEntryDialogComponent, {
      data: { date: entry.begin, edit: true, content: entry },
      panelClass: 'app-dialog-style'
    });

    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result as AppDialogDataWorkEntry;

      if (dialogResult) {
        if (dialogResult.edit === true)  {
          for (const item of this._globals.sharedEntries) {
            if (item.id === entry.id) {
              item.id = dialogResult.content.id;
              item.begin = dialogResult.content.begin;
              item.end = dialogResult.content.end;
              item.description = dialogResult.content.description;
              item.type = dialogResult.content.type;
              item.activity = dialogResult.content.activity;
              item.ticketReferences = dialogResult.content.ticketReferences;

              this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());

              // update stats
              this.updateStats(dialogResult.date);
            }
          }
        }
      }
    });
  }

  onNewTimeBasedAbsenceDialog(date: Date) {
    // ensure hotkeys are disabled while editing
    this.globals.hotkeyEnabled = false;

    date = this.getWorkEntryStart(date);

    const dialogRef = this.timeBasedAbsenceDialog.open(TimeBasedAbsenceDialogComponent, {
      data: { date: date, edit: false, data: undefined, presence: this.presenceToday },
      panelClass: 'app-dialog-style'
    });

    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result as AppDialogDataWorkEntry;
      this.globals.hotkeyEnabled = true;

      if (dialogResult) {
        if (dialogResult.edit === false) {
          if (this.isSelectedDate(dialogResult.date)) {
            this._globals.sharedEntries.push(dialogResult.content);
            this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());
            this._userInfoService.showInfoMessage(this.messageAbsenceBooked, 2000);

            // update stats
            this.updateStats(dialogResult.date);
          }
        }
      }
    });
  }

  onNewAllDayBasedAbsenceDialog(date: Date) {
    // ensure hotkeys are disabled while editing
    this.globals.hotkeyEnabled = false;

    const dialogRef = this.allDayAbsenceDialog.open(AllDayAbsenceDialogComponent, {
      data: { date: date, edit: false, data: undefined },
      panelClass: 'app-dialog-style'
    });

    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result as AppDialogDataWorkEntry;
      this.globals.hotkeyEnabled = true;

      if (dialogResult) {
        if (dialogResult.edit === false) {
          if (this.isSelectedDate(dialogResult.date)) {
            this._globals.sharedEntries.push(dialogResult.content);
            this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());
            this._userInfoService.showInfoMessage(this.messageAbsenceBooked, 2000);

            // update stats
            this.updateStats(dialogResult.date);
          }
        }
      }
    });
  }

  onNewWorkEntryDialog(date: Date, entry?: WorkHourEntry): void {
    // ensure hotkeys are disabled while editing
    this.globals.hotkeyEnabled = false;

    date = this.getWorkEntryStart(date);

    const dialogRef = this.workEntryDialog.open(WorkHourEntryDialogComponent, {
      data: {date: date, edit: false, content: entry, presence: this.presenceToday},
      panelClass: 'app-dialog-style'
    });

    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result as AppDialogDataWorkEntry;
      this.globals.hotkeyEnabled = true;

      if (dialogResult) {
        if (dialogResult.edit === false) {
          if (this.isSelectedDate(dialogResult.date)) {
            this._globals.sharedEntries.push(dialogResult.content);
            this._globals.sharedEntries.sort((a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf());
            this._userInfoService.showInfoMessage(this.messageEntryCreated, 2000);

            // update stats
            this.updateStats(dialogResult.date);
          }
        }
      }
    });
  }

  onNewWorkEntryHover(event: any) {
    this.disableQuickActions = false;
  }

  onNewWorkEntryLeave(event: any) {
    this.disableQuickActions = true;
  }

  getQuickActionVisibility(): string {
    if (this.disableQuickActions) {
      return 'hidden';
    } else {
      return 'visible';
    }
  }

  isTimeBasedAbsence(entry: WorkHourEntry): boolean {
    let result = false;

    this._globals.entryDefinition.absenceTimeBased.forEach(
      (val: string, key: string, map: Map<string, string>) => {
        if (val === entry.description) {
          result = true;
        }
      }
    );

    return result;
  }

  isPublicHoliday(entry: WorkHourEntry): boolean {
    if (entry.type.startsWith('Feiertag')) {
      return true;
    } else {
      return false;
    }
  }

  isAllDayAbsence(entry: WorkHourEntry): boolean {
    let result = false;

    this._globals.entryDefinition.absenceAllDay.forEach(
      (val: string, key: string, map: Map<string, string>) => {
        if (val === entry.description &&
            entry.begin === entry.end) {
          result = true;
        }
      }
    );

    return result;
  }

  handleHasFinishedWorkEvent() {
    this.getTodaysPresence(new Date(Date.now()));
  }

  handleHasStartedWorkEvent() {
    this.getTodaysPresence(new Date(Date.now()));
  }

  /** Update daily and weekly statistics */
  private updateStats(date: Date) {
    if (date) {
      this.getDailyStats(date);
      this.getWeeklyStats(date);
    }
  }

  /** Check if date is selected date */
  private isSelectedDate(date: Date) {
    if (this.selectedDate) {
      const selected = new Date(this.selectedDate);

      if (date.getFullYear() === selected.getFullYear() &&
          date.getDate() === selected.getDate() &&
          date.getDay() === selected.getDay()) {
        return true;
      }
    }

    return false;
  }

  private getWorkEntryStart(date: Date): Date {
    let entryStartDateTime = new Date(date);

    // set default start time
    entryStartDateTime.setHours(8);
    entryStartDateTime.setMinutes(0);
    entryStartDateTime.setSeconds(0);
    entryStartDateTime.setMilliseconds(0);

    // if presence is set use its start time
    if (this.presenceToday && this.presenceToday.begin) {
      entryStartDateTime = new Date(this.presenceToday.begin);
    }

    // if there are already work entries get the last one's end time
    if (this._globals.sharedEntries.length > 0) {
      const workEntry = this._globals.sharedEntries[this._globals.sharedEntries.length - 1];

      if (!this.isAllDayAbsence(workEntry) &&
          !this.isPublicHoliday(workEntry)) {
        entryStartDateTime = new Date(workEntry.end);
      }
    }

    return entryStartDateTime;
  }

  /** handle 403 / not allowed response */
  private handleHttpResponseNotAllowed(response: HttpErrorResponse) {
    if (response.status === 403) {
      this._userInfoService.showInfoMessage(this.messageCantEditEntry, 2500);
    }
  }
}
