import { BirthdayListItem } from '@shared/birthday-list-item.interface';
import { Component, OnInit, OnDestroy, ɵgetHostElement, HostBinding } from '@angular/core';
import { EmployeeContact } from '@shared/api/employee-contact.interface';
import { first } from 'rxjs/operators';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { ApiService } from '@services/api/api.service';
import { compareAsc, isAfter, isSameDay, isBefore, isLeapYear } from 'date-fns';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ContactDetailDialogComponent } from '@components/dialogs/contact-detail-dialog/contact-detail-dialog.component';

@Component({
  selector: 'shared-birthday-list',
  templateUrl: './birthday-list.component.html',
  styleUrls: ['./birthday-list.component.css'],
})
export class BirthdayListComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public birthdays: BirthdayListItem[] = [];
  private readonly BITHDAY_LIST_DATES_COUNT = 5;

  constructor(
    private _gravatarService: GravatarService,
    private _apiService: ApiService,
    public contactDetailDialog: MatDialog
  ) {}

  private static createBirthdayHelperListEntry(
    contact: EmployeeContact,
    currentYear: number
  ): { contact: EmployeeContact; affectedDate: Date } {
    const tempDate = new Date(contact.doB);
    if (isLeapYear(currentYear) && tempDate.getMonth() === 2 && tempDate.getDate() === 29) {
      tempDate.setDate(tempDate.getDate() + 1);
    }
    return {
      contact,
      affectedDate: new Date(currentYear, tempDate.getMonth(), tempDate.getDate()),
    };
  }

  public ngOnInit(): void {
    this.getBirthdays();
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  showContactInfo(birthday: BirthdayListItem): void {
    const dialogRef = this.contactDetailDialog.open(ContactDetailDialogComponent, {
      data: birthday.contact,
      panelClass: 'app-dialog-style',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  private getBirthdays(): void {
    this.subscriptions.add(
      this._apiService
        .getContacts()
        .pipe(first())
        .subscribe((contacts) => {
          this.birthdays = this.createBirthdayData(contacts as EmployeeContact[]);
        })
    );
  }

  private createBirthdayData(contacts: EmployeeContact[]): BirthdayListItem[] {
    const birthdayList: BirthdayListItem[] = [];
    const birthdayHelperList: {
      contact: EmployeeContact;
      affectedDate: Date;
    }[] = [];
    const currentDate = new Date();
    // Create a helper list with normalized birthday to the current year.
    contacts.forEach((contact) => {
      birthdayHelperList.push(BirthdayListComponent.createBirthdayHelperListEntry(contact, currentDate.getFullYear()));
    });
    // Sort the normalized list by normalized birthdays.
    birthdayHelperList.sort((a, b) => compareAsc(a.affectedDate, b.affectedDate));
    // Resort list so that the first element is equal or after the current date
    // and dates before are at the end of the list.
    const afterOrEqualList = birthdayHelperList.filter(
      (element) => isAfter(element.affectedDate, currentDate) || isSameDay(element.affectedDate, currentDate)
    );
    const beforeList = birthdayHelperList.filter((element) => isBefore(element.affectedDate, currentDate));
    const resortedList = afterOrEqualList.concat(beforeList);
    // Create the birthday list. It includes the current birthday(s) if there are some or one.
    // And additionally upcoming birthday dates no matter how much persons will
    // have birthday at the same day.
    let lastDate: Date;
    let dateCount = this.BITHDAY_LIST_DATES_COUNT;
    for (let i = 0; i < resortedList.length; i++) {
      const contact = resortedList[i].contact;
      birthdayList.push({
        gravatarEmail: contact.email,
        date: contact.doB,
        name: `${contact.firstName} ${contact.lastName}`,
        isToday: isSameDay(resortedList[i].affectedDate, currentDate),
        contact: contact,
      });
      if ((!lastDate || !isSameDay(lastDate, resortedList[i].affectedDate)) && dateCount > 0) {
        lastDate = resortedList[i].affectedDate;
        dateCount--;
      }
      if (dateCount === 0 && !isSameDay(lastDate, resortedList[i].affectedDate)) {
        birthdayList.pop();
        break;
      }
    }
    return birthdayList;
  }
}
