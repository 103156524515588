import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeContact } from '@shared/api/employee-contact.interface';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { Phone } from '@shared/api/phone.interface';
import { TranslateService } from '@ngx-translate/core';
import { AvatarSize } from '@components/ui/avatar/avatar-size.enum';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';
import { Globals } from '@shared/globals';

@Component({
  selector: 'app-contact-detail-dialog',
  templateUrl: './contact-detail-dialog.component.html',
  styleUrls: ['./contact-detail-dialog.component.css'],
})
export class ContactDetailDialogComponent implements OnInit {
  sortedPhoneList = new Array<Phone>();

  // translations
  phoneUnspecified = 'Phone';
  phoneWork = 'Phone (work)';
  phoneWorkMobile = 'Mobile (work)';
  phonePriv = 'Phone (private)';
  phonePrivMobile = 'Mobile (private)';

  public readonly globals: Globals;
  public readonly avatarSize = AvatarSize;
  public readonly avatarStatus = AvatarStatus;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeContact,
    private _gravatarService: GravatarService,
    private _translateService: TranslateService,
    private _globals: Globals
  ) {
    this.globals = this._globals;

    // translations
    this._translateService.get('app.contact.phone').subscribe((text) => (this.phoneUnspecified = text));

    this._translateService.get('app.contact.phoneWork').subscribe((text) => (this.phoneWork = text));

    this._translateService.get('app.contact.phoneWorkMobile').subscribe((text) => (this.phoneWorkMobile = text));

    this._translateService.get('app.contact.phonePrivate').subscribe((text) => (this.phonePriv = text));

    this._translateService.get('app.contact.phonePrivateMobile').subscribe((text) => (this.phonePrivMobile = text));

    if (data.phones) {
      this.sortedPhoneList = data.phones.map((x) => Object.assign({}, x));
      this.sortedPhoneList.sort((a, b) => (a.type < b.type ? 1 : -1));
    }
  }

  ngOnInit() {}

  getGravatar(email: string): string {
    return this._gravatarService.getGravatar(email, 100);
  }

  getPhoneTypeViewValue(phone: Phone): string {
    switch (phone.type) {
      case 'work_phone':
        return this.phoneWork;
      case 'work_mobile':
        return this.phoneWorkMobile;
      case 'private_phone':
        return this.phonePriv;
      case 'private_mobile':
        return this.phonePrivMobile;
      default:
        return this.phoneUnspecified;
    }
  }
}
