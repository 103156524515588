
  <div fxFill fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center center">
    <mat-card>
      <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
      <mat-card-content>
        <!-- Header -->
        <div fxLayout="column" fxLayoutAlign="center start">
            <ng-container *ngIf="globals?.ui.isDarkTheme; then darkThemeLogo else lightThemeLogo"></ng-container>
          
            <ng-template #darkThemeLogo>
                <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx-dark.svg" />
            </ng-template>
  
            <ng-template #lightThemeLogo>
                <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx.svg" />
            </ng-template>
            <span class="mat-headline">{{'app.pages.login.resetTitle' | translate}}</span>
        </div>
        <!-- Content -->
        <form #loginForgotForm="ngForm" (ngSubmit)="onSubmitForgot()">
          <mat-form-field>
            <input matInput
                   tabindex="1"
                   type="text"
                   placeholder="{{'app.pages.login.placeholderEnterAccountName' | translate}}"
                   [formControl]="formControlUser">
            <mat-error>{{getUserErrors()}}</mat-error>
          </mat-form-field>
          <!-- Message and action area -->
          <div fxLayout="column">
            <!-- Actions -->
            <div fxLayout="row">
              <button tabindex="4" [disabled]="isLoading" mat-button routerLink="/login" type="button">{{'app.pages.login.btnBack' | translate}}</button>
              <div class="spacer"></div>
              <button tabindex="3" [disabled]="isLoading" mat-raised-button color="primary" type="submit">{{'app.pages.login.btnNext' | translate}}</button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
