import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api/api.service';
import { LoginRecoverPassword } from '@shared/api/login-recover-password.interface';
import { UserInfoService } from '@services/user-info.service';
import { Globals } from '@shared/globals';

@Component({
  selector: 'app-login-recover',
  templateUrl: './login-recover.component.html',
  styleUrls: ['./login-recover.component.css']
})
export class LoginRecoverComponent implements OnInit {
  token: string;
  isLoading = false;
  globals: Globals;

  // error translations
  errorMsgRequiredField: string;
  errorMsgInvalidPwdPattern: string;
  errorMsgPasswordsDontMatch: string;
  errorMsgPasswordsMatch: string;
  errorMsgGenericError: string;
  errorMsgInvalidTokenError: string;

  // form controls
  formControlRecoverToken = new FormControl('', [
    Validators.required
  ]);

  patternNewPassword     = '.{8,}';
  formControlNewPassword = new FormControl('', [
    Validators.pattern(this.patternNewPassword),
    Validators.required
  ]);

  formControlConfirmPassword = new FormControl('', [
    Validators.pattern(this.patternNewPassword),
    Validators.required
  ]);

  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _globals: Globals,
              private _translateService: TranslateService,
              private _apiService: ApiService,
              private _userInfoService: UserInfoService) {
    this._activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit() {
    this.globals = this._globals;

    this._translateService.get('app.dialog.changePwdPatternError')
      .subscribe(translation => this.errorMsgInvalidPwdPattern = translation);

    this._translateService.get('app.dialog.changePwdMatchError')
      .subscribe(translation => this.errorMsgPasswordsMatch = translation);

    this._translateService.get('app.dialog.changePwdDontMatchError')
      .subscribe(translation => this.errorMsgPasswordsDontMatch = translation);

    this._translateService.get('app.dialog.changePwdRequiredError')
      .subscribe(translation => this.errorMsgRequiredField = translation);

    this._translateService.get('app.dialog.changePwdGenericError')
      .subscribe(translation => this.errorMsgGenericError = translation);

    this._translateService.get('app.pages.login.invalidTokenError')
      .subscribe(translation => this.errorMsgInvalidTokenError = translation);
  }

  onSubmitRecover() {
    if (!this.formControlRecoverToken.invalid ||
        !this.formControlNewPassword.invalid ||
        !this.formControlConfirmPassword.invalid) {
      // compare retyped password & send api request
      if (this.formControlNewPassword.value === this.formControlConfirmPassword.value) {
        const requestData: LoginRecoverPassword = {
          recoverToken: this.token ? this.token : this.formControlRecoverToken.value,
          newPassword: this.formControlConfirmPassword.value
        };
        console.log(requestData);
        this.isLoading = true;
        this._apiService.putLoginRecoverPassword(requestData)
          .subscribe(
            reply => {
              this.isLoading = false;
              this._router.navigate(['/login']);
            },
            error => {
              this.isLoading = false;
              if (error.status === 400) {
                if (error.error === 'invalid password recovery token') {
                  this.formControlRecoverToken.setErrors({ invalidToken: true });

                  if (this.token) {
                    this._userInfoService.showInfoMessage(this.errorMsgInvalidTokenError, 2500);
                    this._router.navigate(['/login-reset']);
                  }
                }
              } else {
                this.formControlConfirmPassword.setErrors({ genericError: true });
              }
            }
          );
      } else {
        this.formControlConfirmPassword.setErrors({ notEqual: true });
      }
    }
  }

  getRecoverTokenErrors(): string {
    if (this.formControlRecoverToken.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlRecoverToken.hasError('invalidToken')) {
      return this.errorMsgInvalidTokenError;
    }
  }

  getNewPwdErrors(): string {
    if (this.formControlNewPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlNewPassword.hasError('pattern')) {
      return this.errorMsgInvalidPwdPattern;
    }

    if (this.formControlNewPassword.hasError('passwordMatch')) {
      return this.errorMsgPasswordsMatch;
    }
  }

  getConfirmPwdErrors(): string {
    if (this.formControlConfirmPassword.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlConfirmPassword.hasError('pattern')) {
      return this.errorMsgInvalidPwdPattern;
    }

    if (this.formControlConfirmPassword.hasError('notEqual')) {
      return this.errorMsgPasswordsDontMatch;
    }

    if (this.formControlConfirmPassword.hasError('genericError')) {
      return this.errorMsgGenericError;
    }
  }
}
