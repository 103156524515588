
  <div fxFill fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center center">
      <mat-card>
        <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
        <mat-card-content>
          <!-- Header -->
          <div fxLayout="column" fxLayoutAlign="center start">
              <ng-container *ngIf="globals?.ui.isDarkTheme; then darkThemeLogo else lightThemeLogo"></ng-container>
          
              <ng-template #darkThemeLogo>
                  <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx-dark.svg" />
              </ng-template>
    
              <ng-template #lightThemeLogo>
                  <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx.svg" />
              </ng-template>
              <span class="mat-headline">{{'app.pages.login.recoverTitle' | translate}}</span>
          </div>
          <!-- Content -->
          <form #loginRecoverForm="ngForm" (ngSubmit)="onSubmitRecover()">
            <mat-form-field *ngIf="!token">
              <input matInput
                     tabindex="1"
                     cdkInitialFocus
                     type="text"
                     required
                     placeholder="{{'app.pages.login.placeholderRecoverToken' | translate}}" 
                     [formControl]="formControlRecoverToken">
              <mat-error *ngIf="formControlRecoverToken.invalid">{{getRecoverTokenErrors()}}</mat-error>
            </mat-form-field>
            <div class="change-pwd-message">{{'app.dialog.changePwdStrengthHint'|translate}}</div>
            <mat-form-field>
              <input matInput
                     tabindex="2" 
                     type="password"
                     required
                     placeholder="{{'app.dialog.changePwdNewPwd'|translate}}"
                     [formControl]="formControlNewPassword"
                     [pattern]="patternNewPassword"/>
              <mat-error *ngIf="formControlNewPassword.invalid">{{getNewPwdErrors()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput
                     tabindex="3" 
                     type="password"
                     required
                     placeholder="{{'app.dialog.changePwdConfirmPwd'|translate}}"
                     [formControl]="formControlConfirmPassword"
                     [pattern]="patternNewPassword"/>
              <mat-error *ngIf="formControlConfirmPassword.invalid">{{getConfirmPwdErrors()}}</mat-error>
            </mat-form-field>
            <!-- Message and action area -->
            <div fxLayout="column">
              <!-- Actions -->
              <div fxLayout="row">
                <button tabindex="5" [disabled]="isLoading" mat-button routerLink="/login" type="button">{{'app.pages.login.btnBack' | translate}}</button>
                <div class="spacer"></div>
                <button tabindex="4" [disabled]="isLoading" mat-raised-button color="primary" type="submit">{{'app.pages.login.btnNext' | translate}}</button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  