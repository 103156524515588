import { TokenData } from '@shared/api/token-data.interface';
import { Base64 } from '@shared/base64';

export class Token {
    rawToken: string;
    tokenData: TokenData;

    constructor(token: string) {
        if (token) {
            this.rawToken = token;

            // try to decode token data
            const parts = this.rawToken.split('.');
            // a valid token should have 3 parts, the second part should contain base64 data
            if (parts.length === 3) {
                this.tokenData = this.parseTokenData(parts[1]);
            }
        }
    }

    private parseTokenData(base64: string): TokenData {
        const decodedData = Base64.decodeUnicode(base64);

        if (decodedData) {
            const jsObj = new Object(JSON.parse(decodedData));

            let impersonateAccess = false;
            if (jsObj['CanImpersonate'] !== undefined) {
                impersonateAccess = true;
            }

            const data: TokenData = {
                identifier: jsObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
                userGivenname: jsObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
                userSurname: jsObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
                userEmail: jsObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
                knownClient: jsObj['KnownClient'],
                canImpersonate: impersonateAccess,
                expires: jsObj['exp']
            };

            return data;
        }

        return undefined;
    }
}
