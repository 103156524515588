<app-toolbar></app-toolbar>
<div class="toolbar-content-spacer"></div>
<div *ngIf="globals?.appConfig?.environment !== 'production'" class="toolbar-content-spacer-test"></div>
<div class="profile-container" fxLayout="column">
    <!-- Password -->
    <span class="mat-headline">{{ 'app.pages.profile.passwdHeadline' | translate }}</span>
    <mat-divider></mat-divider>
    <div class="profile-section" fxLayout="column" fxLayout.gt-sm="row">
        <!-- Description -->
        <span class="mat-subheading-1 profile-section-text" fxFlex="grow" fxFlex.gt-sm="25">{{
            'app.pages.profile.passwdSectionText' | translate
        }}</span>
        <!-- Content -->
        <div fxLayout="column">
            <div>
                <button mat-raised-button (click)="onChangePasswdClick($event)">
                    {{ 'app.btnChangePasswd' | translate }}
                </button>
            </div>
        </div>
    </div>
    <!-- Avatar Details -->
    <span class="mat-headline">{{ 'app.pages.profile.avatarHeadline' | translate }}</span>
    <mat-divider></mat-divider>
    <div class="profile-section" fxLayout="column" fxLayout.gt-sm="row">
        <!-- Description -->
        <span class="mat-subheading-1 profile-section-text" fxFlex="grow" fxFlex.gt-sm="25">{{
            'app.pages.profile.avatarSectionText' | translate
        }}</span>
        <!-- Content -->
        <div fxLayout="column">
            <div><shared-avatar [size]="avatarSize.Large" [status]="globals.userStatus$|async"></shared-avatar></div>
        </div>
    </div>
    <!-- Profile Details -->
    <span class="mat-headline">{{ 'app.pages.profile.detailsHeadline' | translate }}</span>
    <mat-divider></mat-divider>
    <div class="profile-section" fxLayout="column" fxLayout.gt-sm="row">
        <!-- Description -->
        <span class="mat-subheading-1 profile-section-text" fxFlex="grow" fxFlex.gt-sm="25">{{
            'app.pages.profile.detailsSectionText' | translate
        }}</span>
        <!-- Content -->
        <div fxLayout="column" fxFlex="grow">
            <ng-container *ngIf="!isLoading">
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{ 'app.pages.profile.detailsName' | translate }}</span>
                    <span class="mat-subheading-1">{{ profileInfo?.firstName }}&nbsp;{{ profileInfo?.lastName }}</span>
                </div>
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{ 'app.pages.profile.detailsDoB' | translate }}</span>
                    <span class="mat-subheading-1">{{ profileInfo?.doB | date: 'dd. MMM yyyy' }}</span>
                </div>
                <mat-divider></mat-divider>
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{
                        'app.pages.profile.detailsCompany' | translate
                    }}</span>
                    <span class="mat-subheading-1">{{ profileInfo?.company }}</span>
                </div>
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{
                        'app.pages.profile.detailsDepartment' | translate
                    }}</span>
                    <span class="mat-subheading-1">{{ profileInfo?.department }}</span>
                </div>
                <mat-divider></mat-divider>
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{ 'app.pages.profile.detailsEmail' | translate }}</span>
                    <span class="mat-subheading-1">{{ profileInfo?.email }}</span>
                </div>
                <ng-container *ngFor="let phone of profileInfo?.phones">
                    <div class="mat-typography" fxFlex="grow" fxLayout="row">
                        <span class="mat-subheading-1" fxFlex="35">{{ getPhoneTypeViewValue(phone) }}</span>
                        <span class="mat-subheading-1">{{ phone.number | phone }}</span>
                    </div>
                </ng-container>
                <mat-divider></mat-divider>
                <div class="mat-typography" fxFlex="grow" fxLayout="row">
                    <span class="mat-subheading-1" fxFlex="35">{{
                        'app.pages.profile.detailsAddress' | translate
                    }}</span>
                    <span class="mat-subheading-1">
                        {{ profileInfo?.address?.street1 }}&nbsp;{{ profileInfo?.address?.street2 }}&nbsp;{{
                            profileInfo?.address?.houseNumber
                        }}<br />
                        {{ profileInfo?.address?.postalCode }}&nbsp;{{ profileInfo?.address?.city }}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
