import { Pipe, PipeTransform } from '@angular/core';

// js libs
import * as libphone from 'google-libphonenumber';

@Pipe({
    name: 'phone'
})

export class PhoneValuePipe implements PipeTransform {
    transform(value: any, args: any[]): any {
        const phoneFormat = libphone.PhoneNumberFormat;
        const phoneUtil = libphone.PhoneNumberUtil.getInstance();

        const inputValue = value as string;
        if (inputValue.startsWith('+49') || inputValue.startsWith('0049')) {
            const number = phoneUtil.parseAndKeepRawInput(inputValue, 'DE');
            return phoneUtil.format(number, phoneFormat.INTERNATIONAL);
        }

        return inputValue;
    }
}
