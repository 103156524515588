import { Component, OnInit, ViewChild, Inject, KeyValueDiffers } from '@angular/core';
import { AppDialogDataWorkEntry } from '@shared/app-dialog-data-work-entry.interface';
import { MatSelect } from '@angular/material/select';
import { Globals } from '@shared/globals';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { ApiService } from '@services/api/api.service';
import { UserInfoService } from '@services/user-info.service';
import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { TicketReference } from '@shared/api/ticket-reference.interface';
import { WorkHourEntryResponse } from '@shared/api/work-hour-entry-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { formatISO } from 'date-fns';

@Component({
  selector: 'app-all-day-absence-dialog',
  templateUrl: './all-day-absence-dialog.component.html',
  styleUrls: ['./all-day-absence-dialog.component.css']
})
export class AllDayAbsenceDialogComponent implements OnInit {
  dialogData: AppDialogDataWorkEntry;
  globals: Globals;
  today = new Date(Date.now());
  isLoading = false;

  optionAbsenceFormControl = new FormControl();
  selectedFromDateFormControl = new FormControl(this.today);
  selectedToDateFormControl = new FormControl(this.today);

  // translate strings
  messageVerifyDates: string;
  messageCantEditEntry: string;
  messageCantCreateEntry: string;

  @ViewChild(MatSelect, { static: true }) absenceTypeSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<AllDayAbsenceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AppDialogDataWorkEntry,
              private _globals: Globals,
              private _authGuardService: AuthGuardService,
              private _apiService: ApiService,
              private _userInfoService: UserInfoService,
              private _translateService: TranslateService) {
    this.dialogData = data as AppDialogDataWorkEntry;
    this.globals = _globals;
    this.optionAbsenceFormControl.setValue('paidLeave');

    const from = new Date(this.dialogData.date);
    from.setHours(2, 0, 0, 0);
    this.selectedFromDateFormControl.setValue(from);

    const to = new Date(from);
    to.setHours(2, 0, 0, 0);
    this.selectedToDateFormControl.setValue(to);
  }

  ngOnInit() {
    this._translateService.get('app.message.verifyTicket')
      .subscribe(text => this.messageVerifyDates = text);

    this._translateService.get('app.message.editEntryNotAllowed')
      .subscribe(text => this.messageCantEditEntry = text);

    this._translateService.get('app.message.createEntryNotAllowed')
      .subscribe(text => this.messageCantCreateEntry = text);
  }

  setFromDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedFromDateFormControl.setValue(event.value);
  }

  setToDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedToDateFormControl.setValue(event.value);
  }

  onSaveWorkEntry(event: any) {
    const fromDateTime = this.selectedFromDateFormControl.value as Date;
    let toDateTime   = this.selectedToDateFormControl.value as Date;

    /** ToDo: TEMPORARY SOLUTION - ToDate will have to be chooseable by user */
    toDateTime = fromDateTime;

    // content checks
    if (toDateTime.valueOf() < fromDateTime.valueOf()) {
      this._userInfoService.showInfoMessage(this.messageVerifyDates, 3000);
      this.isLoading = false;
      return;
    }

    // all content is ok - go ahead and save
    const dateRange = new Array<Date>();
    dateRange.push(fromDateTime);

    for (const date of dateRange) {
      this.sendHttpRequest(date);
    }
  }

  sendHttpRequest(date: Date) {
    this.isLoading = true;

    date.setHours(2, 0, 0, 0);

    const entry: WorkHourEntry = {
      id: undefined,
      begin: formatISO(date),
      end: formatISO(date),
      type: this._globals.entryDefinition.absenceAllDay.get(this.optionAbsenceFormControl.value),
      activity: undefined,
      description: this._globals.entryDefinition.absenceAllDay.get(this.optionAbsenceFormControl.value),
      ticketReferences: new Array<TicketReference>()
    };

    if (this.dialogData.edit === false) {
      // is new entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.postWorkHourEntry(entry)
        .subscribe(
          response => {
            this.isLoading = false;
            entry.id = (response as WorkHourEntryResponse).id;
            dialogResult.content.id = entry.id;
            this.dialogRef.close(dialogResult);
          },
          error => {
            this.handleHttpErrorResponse(error, dialogResult);
            this.isLoading = false;
          }
        );
    } else {
      // edit existing entry
      const dialogResult: AppDialogDataWorkEntry = {
        date: new Date(entry.begin),
        edit: this.dialogData.edit,
        content: entry,
        presence: null
      };

      this._apiService.putWorkHourEntry(entry)
      .subscribe(
        response => {
          this.isLoading = false;
          this.dialogRef.close(dialogResult);
        },
        error => {
          this.handleHttpErrorResponse(error, dialogResult);
          console.log(error);
          this.isLoading = false;
        }
      );
    }
  }

  handleHttpErrorResponse(error: HttpErrorResponse, data: any) {
    if (error.status === 401) {
      sessionStorage.setItem(this._globals.localStorageKeys.data.allDayAbsence, JSON.stringify(data));
      this.dialogRef.close();
      this._authGuardService.logout();
    }

    if (error.status === 403) {
      if (this.dialogData.edit) {
        this._userInfoService.showInfoMessage(this.messageCantEditEntry, 2500);
      } else {
        this._userInfoService.showInfoMessage(this.messageCantCreateEntry, 2500);
      }
    }
  }
}
