import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { User } from '@shared/api/user.interface';
import { Router } from '@angular/router';
import { UserInfoService } from '@services/user-info.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Globals } from '@shared/globals';

@Component({
  selector: 'app-login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['./login-forgot.component.css']
})
export class LoginForgotComponent implements OnInit {
  isLoading = false;
  globals: Globals;

  // translations
  errorMsgRequiredField: string;
  errorMsgInvalidLogin: string;
  resetTriggeredMsg: string;

  // form controls
  formControlUser = new FormControl('', [
    Validators.required
  ]);

  constructor(private _apiService: ApiService,
              private _userInfoService: UserInfoService,
              private _translateService: TranslateService,
              private _globals: Globals,
              public router: Router) { }

  ngOnInit() {
    this.globals = this._globals;

    this._translateService.get('app.pages.login.resetFailedError')
      .subscribe(translation => this.errorMsgInvalidLogin = translation);

    this._translateService.get('app.dialog.changePwdRequiredError')
      .subscribe(translation => this.errorMsgRequiredField = translation);

    this._translateService.get('app.pages.login.resetTriggeredMsg')
      .subscribe(translation => this.resetTriggeredMsg = translation);
  }

  onSubmitForgot() {
    if (!this.formControlUser.invalid) {
      this.isLoading = true;
      const user: User = { login: this.formControlUser.value, password: '' };
      this._apiService.postResetPassword(user)
        .subscribe(
          reply => {
            this.isLoading = false;
            this._userInfoService.showInfoMessage(this.resetTriggeredMsg, 2500);
            this.router.navigate(['/login-recover']);
          },
          error => {
            this.isLoading = false;
            this.formControlUser.setErrors({ genericError: true });
          }
        );
    }
  }

  getUserErrors(): string {
    if (this.formControlUser.hasError('required')) {
      return this.errorMsgRequiredField;
    }

    if (this.formControlUser.hasError('genericError')) {
      return this.errorMsgInvalidLogin;
    }
  }
}
