import { EntryColors } from '@shared/api/enum/entry-colors.enum';

export class EntryTypes {
    colors: {[key: string]: string};

    // absence types
    absenceAllDay: Map<string, string>;
    absenceTimeBased: Map<string, string>;
    workEntryTypes: Map<string, string>;
    workEntryActivities: Map<string, string>;
    publicHolidays: Map<string, string>;

    constructor() {
        // if colors are added the absence-colors enum must be extended too
        this.colors = {
            'blue'  : '#1976d2',          // used for actual absence (not working time)
            'yellow': '#ffca28',          // used for regular work
            'green' : '#4caf50',          // used for driving to and from onsite events
            'orange': '#ff9800'           // used for regular work onsite with customer
        };

        this.initializeAbsenceLists();
    }

    /**
     * Map a absence to a color for viewing purposes
     * @param absence Absence type representation
     * @returns Color string in HEX notation
     */
    mapColor(absence: string): string {
        switch (absence) {
            case this.absenceAllDay.get('paidLeave'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('unpaiedLeave'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('sickLeave'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('sickLeaveChild'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('overtimeDeduct'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('maternityLeave'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('maternityProtection'):
                return this.colors[EntryColors.Blue];
            case this.absenceAllDay.get('lessons'):
                return this.colors[EntryColors.Yellow];
            case this.workEntryTypes.get('work'):
                return this.colors[EntryColors.Yellow];
            case this.workEntryTypes.get('workOnPremise'):
                return this.colors[EntryColors.Orange];
            case this.workEntryTypes.get('journeyTime'):
                return this.colors[EntryColors.Green];
            case this.publicHolidays.get('publicHoliday'):
                return this.colors[EntryColors.Blue];
        }

        return undefined;
    }

    private initializeAbsenceLists() {
        // all day absence
        this.absenceAllDay = new Map([
            ['paidLeave', 'Urlaub'],
            ['overtimeDeduct', 'Absetzer'],
            ['lessons', 'Unterricht'],
            ['sickLeave', 'Krank'],
            ['sickLeaveChild', 'Krank (Kind)'],
            ['unpaiedLeave', 'Auszeit'],
            ['maternityLeave', 'Elternzeit'],
            ['maternityProtection', 'Mutterschutz']
        ]);

        // time based absence
        this.absenceTimeBased = new Map([
            ['overtimeDeduct', 'Absetzer']
        ]);

        // work time entries
        this.workEntryTypes = new Map([
            ['workOnPremise', 'Arbeit vor Ort'],
            ['work', 'Arbeit'],
            ['journeyTime', 'Fahrtzeit']
        ]);

        // work entry activities
        this.workEntryActivities = new Map([
            ['progDesignDoc', 'Prog, Design, Dok'],
            ['meetingTelco', 'Meeting & TelCo'],
            ['itService', 'IT Service'],
            ['application', 'Applikation'],
            ['administration', 'Verwaltung'],
            ['seminar', 'Schulung']
        ]);

        // public holiday
        this.publicHolidays = new Map([
            ['publicHoliday', 'Feiertag']
        ]);
    }
}
