import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class GravatarService {
  baseUrl = 'https://www.gravatar.com/avatar/';
  size = 100;


  constructor() { }

  getGravatar(email: string, size?: number): string {
    let url: string;

    let gravatarSize = this.size;
    if (size) {
      gravatarSize = Math.floor(size);
    }

    if (email) {
      const md5email = Md5.hashStr(email);
      url = new Array<string>(this.baseUrl,
                                  md5email.toString(),
                                  '?d=identicon&',
                                  's=', gravatarSize.toString()).join('');
    } else {
      url = new Array<string>(this.baseUrl, '?d=identicon').join('');
    }

    return url;
  }
}
