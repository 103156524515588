import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Globals } from '@shared/globals';
import { environment } from '@environments/environment';
import { Employee } from '@shared/api/employee.interface';
import { ApiService } from '@services/api/api.service';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { TranslateService } from '@ngx-translate/core';
import { Phone } from '@shared/api/phone.interface';
import { ChangePasswordDialogComponent } from '@components/dialogs/change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AvatarSize } from '@components/ui/avatar/avatar-size.enum';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  environment = environment;
  globals: Globals;
  profileInfo: Employee;
  gravatarUrl: string;
  isLoading = false;
  public readonly avatarSize = AvatarSize;
  public readonly avatarStatus = AvatarStatus;

  // translations
  phoneUnspecified = 'Phone';
  phoneWork = 'Phone (work)';
  phoneWorkMobile = 'Mobile (work)';
  phonePriv = 'Phone (private)';
  phonePrivMobile = 'Mobile (private)';

  constructor(
    private _globals: Globals,
    private _apiService: ApiService,
    private _gravatarService: GravatarService,
    private _translateService: TranslateService,
    public changePasswordDialog: MatDialog
  ) {
    this.globals = _globals;

    // translations
    this._translateService.get('app.contact.phone').subscribe((text) => (this.phoneUnspecified = text));

    this._translateService.get('app.contact.phoneWork').subscribe((text) => (this.phoneWork = text));

    this._translateService.get('app.contact.phoneWorkMobile').subscribe((text) => (this.phoneWorkMobile = text));

    this._translateService.get('app.contact.phonePrivate').subscribe((text) => (this.phonePriv = text));

    this._translateService.get('app.contact.phonePrivateMobile').subscribe((text) => (this.phonePrivMobile = text));
  }

  ngOnInit() {
    this._globals.isLoading = true;
    this._apiService.getEmployee().subscribe(
      (contact) => {
        this.profileInfo = contact as Employee;
        this.profileInfo.phones.sort((a, b) => (a.type < b.type ? 1 : -1));
        this.isLoading = false;
        this._globals.isLoading = false;
        this.getGravatar();
      },
      (error) => {
        this.isLoading = false;
        this._globals.isLoading = false;
        console.warn(error);
      }
    );
  }

  getGravatar() {
    if (this.profileInfo) {
      this.gravatarUrl = this._gravatarService.getGravatar(this.profileInfo.email);
    }
  }

  onChangePasswdClick(event: any) {
    // change password dialog
    this.changePasswordDialog.open(ChangePasswordDialogComponent, {
      data: {},
      panelClass: 'app-dialog-style',
    });
  }

  getPhoneTypeViewValue(phone: Phone): string {
    switch (phone.type) {
      case 'work_phone':
        return this.phoneWork;
      case 'work_mobile':
        return this.phoneWorkMobile;
      case 'private_phone':
        return this.phonePriv;
      case 'private_mobile':
        return this.phonePrivMobile;
      default:
        return this.phoneUnspecified;
    }
  }
}
