<mat-progress-bar color="primary" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="dialog-content action-row-bottom-fix">
  <h1 mat-dialog-title><span>{{'app.pages.workHours.newEntryTitle' | translate}}</span> {{dialogData.date | date: 'dd. MMMM yyyy'}}</h1>
  <div mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field>
          <input matInput #inputFromTime placeholder="{{'app.pages.workHours.entry.from' | translate}}" type="time" [(ngModel)]="fromTimeString" [value]="fromTimeString" (ngModelChange)="onFromTimeChanged($event)">
        </mat-form-field>
        <mat-form-field>
          <input matInput #inputToTime placeholder="{{'app.pages.workHours.entry.to' | translate}}" type="time" [(ngModel)]="toTimeString" [value]="toTimeString" (ngModelChange)="onToTimeChanged($event)">
        </mat-form-field>
      </div>

      <mat-form-field>
        <span matPrefix>#&nbsp;</span>
        <input matInput
               #inputTicketId
               [formControl]="formControlTicketId"
               [pattern]="patternTicketId"
               placeholder="{{'app.pages.workHours.entry.issueId' | translate}}">
        <mat-error *ngIf="formControlTicketId.invalid">{{messageVerifyTicket}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select #workTypeSelect placeholder="{{'app.pages.workHours.entry.type' | translate}}" [formControl]="formControlEntryType">
          <mat-option *ngFor="let element of globals?.entryDefinition.workEntryTypes | keyvalue" value="{{element.key}}">{{element.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select #activityTypeSelect placeholder="{{'app.pages.workHours.entry.activity' | translate}}" [formControl]="formControlEntryActivity">
          <mat-option *ngFor="let element of globals?.entryDefinition.workEntryActivities | keyvalue" value="{{element.key}}">{{element.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <textarea matInput 
                  #inputDescription
                  [formControl]="formControlDescription"
                  placeholder="{{'app.pages.workHours.entry.description' | translate}}">
        </textarea>
        <mat-hint align="end">{{formControlDescription.value.length}} / 256</mat-hint>
        <mat-error *ngIf="formControlTicketId.invalid">{{getDescriptionError()}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions position="end">
    <mat-divider></mat-divider>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close [disabled]="isLoading">{{'app.btnCancel' | translate}}</button>
    <button mat-button cdkFocusInitial color="primary" [disabled]="isLoading" (click)="onSaveWorkEntry($event)">{{'app.btnSave' | translate}}</button>
  </mat-dialog-actions>
</div>
