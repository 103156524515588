import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndpoint } from '@services/api/api-endpoint.enum';
import { PresenceEntry } from '@shared/api/presence.interface';
import { Globals } from '@shared/globals';
import { WorkHourEntry } from '@shared/api/work-hour-entry.interface';
import { User } from '@shared/api/user.interface';
import { ChangePassword } from '@shared/api/change-password.interface';
import { LoginRecoverPassword } from '@shared/api/login-recover-password.interface';
import { ApiCustomHeader } from '@services/api/api-custom-header.enum';
import { AppConfig } from '@shared/app-config/app-config';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { EmployeeContact } from '@shared/api/employee-contact.interface';
import { formatISO, format } from 'date-fns';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';
import { EmployeeStatus } from '@shared/api/employee-status';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  hostname: string;

  constructor(private _http: HttpClient, private _authGuardService: AuthGuardService, private _globals: Globals) {
    this._globals.appConfigInit$.subscribe({
      next: (config: AppConfig) => {
        if (config) {
          this.hostname = config.url;
        }
      },
    });
  }

  public getEmployee() {
    const headers = { headers: this.setAuthHeader() };
    let endpoint: string;
    endpoint = new Array<string>(this.hostname, ApiEndpoint.Employee).join('/');

    return this._http.get(endpoint, headers);
  }

  public getContacts() {
    const headers = { headers: this.setAuthHeader() };
    let endpoint: string;
    endpoint = new Array<string>(this.hostname, ApiEndpoint.Contacts).join('/');

    return this._http.get(endpoint, headers);
  }

  putLoginRecoverPassword(changeRequest: LoginRecoverPassword) {
    const url = new Array<string>(this.hostname, ApiEndpoint.Recover).join('/');
    return this._http.put(url, changeRequest);
  }

  putChangePassword(changeRequest: ChangePassword) {
    const headers = { headers: this.setAuthHeader() };
    const url = new Array<string>(this.hostname, ApiEndpoint.Change).join('/');
    return this._http.put(url, changeRequest, headers);
  }

  public resetPresence(day: Date) {
    let url: string;

    url = new Array<string>(
      this.hostname,
      ApiEndpoint.Presence,
      format(day, 'yyyy'),
      format(day, 'MM'),
      format(day, 'dd'),
      'reset'
    ).join('/');
    console.log(url);
    return this._http.get(url, { headers: this.setAuthHeader() });
  }

  public getPresence(year?: Date, month?: Date, day?: Date) {
    const headers = { headers: this.setAuthHeader() };
    let url: string;

    if (year && month && day) {
      url = new Array<string>(
        this.hostname,
        ApiEndpoint.Presence,
        format(year, 'yyyy'),
        format(month, 'MM'),
        format(day, 'dd')
      ).join('/');
      return this._http.get(url, headers);
    }

    if (year && month) {
      url = new Array<string>(this.hostname, ApiEndpoint.Presence, format(year, 'yyyy'), format(month, 'MM')).join('/');
      return this._http.get(url, { headers: this.setAuthHeader() });
    }

    if (year) {
      url = new Array<string>(this.hostname, ApiEndpoint.Presence, format(year, 'yyyy')).join('/');
      return this._http.get(url);
    }

    url = new Array<string>(this.hostname, ApiEndpoint.Presence).join('/');
    return this._http.get(url);
  }

  public postPresence(date: Date, data: PresenceEntry) {
    const headers = { headers: this.setAuthHeader() };
    const url = new Array<string>(
      this.hostname,
      ApiEndpoint.Presence,
      format(date, 'yyyy'),
      format(date, 'MM'),
      format(date, 'dd')
    ).join('/');
    return this._http.post(url, data, headers);
  }

  public putPresence(date: Date, data: PresenceEntry) {
    const headers = { headers: this.setAuthHeader() };
    const url = new Array<string>(
      this.hostname,
      ApiEndpoint.Presence,
      format(date, 'yyyy'),
      format(date, 'MM'),
      format(date, 'dd')
    ).join('/');
    return this._http.put(url, data, headers);
  }

  /**
   * Before sending a request check if the current session is valid e.g.
   * if the user logged in with different accounts in different tabs
   */
  private validateSession(): boolean {
    const session1 = localStorage.getItem(this._globals.localStorageKeys.sessionId);
    const session2 = sessionStorage.getItem(this._globals.localStorageKeys.sessionId);

    if ((session1 !== null && session1 === session2) || session2 === null) {
      sessionStorage.setItem(this._globals.localStorageKeys.sessionId, session1);
      return true;
    }

    return false;
  }

  private setAuthHeader(headers?: HttpHeaders): HttpHeaders {
    if (!this.validateSession()) {
      this._authGuardService.logout();
    }

    let extendedHeaders: HttpHeaders;
    if (headers) {
      extendedHeaders = headers;
    } else {
      extendedHeaders = new HttpHeaders();
    }

    const token = localStorage.getItem(this._globals.localStorageKeys.bearerToken);
    extendedHeaders = extendedHeaders.append('Authorization', 'Bearer ' + token);
    extendedHeaders = extendedHeaders.append(
      ApiCustomHeader.ClientImpersonate,
      (JSON.parse(sessionStorage.getItem(this._globals.localStorageKeys.user)) as EmployeeContact).id
    );

    return extendedHeaders;
  }

  getStats(fromDate?: Date, toDate?: Date) {
    let url = new Array<string>(this.hostname, ApiEndpoint.Stats).join('/');
    let filter: string;

    if (fromDate && toDate) {
      filter = new Array<string>('?from=', formatISO(fromDate), '&to=', formatISO(toDate)).join('');
      url = url + filter;
    }

    return this._http.get(url, { headers: this.setAuthHeader() });
  }

  getWorkHourEntries(year?: Date, month?: Date, day?: Date) {
    let url: string;

    if (year && month && day) {
      url = new Array<string>(
        this.hostname,
        ApiEndpoint.Workhours,
        format(year, 'yyyy'),
        format(month, 'MM'),
        format(day, 'dd')
      ).join('/');
      return this._http.get(url, { headers: this.setAuthHeader() });
    }

    if (year && month) {
      url = new Array<string>(this.hostname, ApiEndpoint.Workhours, format(year, 'yyyy'), format(month, 'MM')).join(
        '/'
      );
      return this._http.get(url, { headers: this.setAuthHeader() });
    }

    if (year) {
      url = new Array<string>(this.hostname, ApiEndpoint.Workhours, format(year, 'yyyy')).join('/');
      return this._http.get(url, { headers: this.setAuthHeader() });
    }

    url = new Array<string>(this.hostname, ApiEndpoint.Workhours).join('/');
    return this._http.get(url, { headers: this.setAuthHeader() });
  }

  deleteWorkHourEntry(entry: WorkHourEntry) {
    const headers = { headers: this.setAuthHeader() };
    const date = new Date(
      this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)
    );
    const url = new Array<string>(
      this.hostname,
      ApiEndpoint.Workhours,
      format(date, 'yyyy'),
      format(date, 'MM'),
      format(date, 'dd'),
      entry.id
    ).join('/');
    return this._http.delete(url, headers);
  }

  putWorkHourEntry(entry: WorkHourEntry) {
    const headers = { headers: this.setAuthHeader() };
    const date = new Date(
      this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)
    );
    const url = new Array<string>(
      this.hostname,
      ApiEndpoint.Workhours,
      format(date, 'yyyy'),
      format(date, 'MM'),
      format(date, 'dd')
    ).join('/');
    return this._http.put(url, entry, headers);
  }

  postWorkHourEntry(entry: WorkHourEntry) {
    const headers = { headers: this.setAuthHeader() };
    const date = new Date(
      this._globals.localeSettings.datePipe.transform(entry.begin, this._globals.localeSettings.dateFormat)
    );
    const url = new Array<string>(
      this.hostname,
      ApiEndpoint.Workhours,
      format(date, 'yyyy'),
      format(date, 'MM'),
      format(date, 'dd')
    ).join('/');
    return this._http.post(url, entry, headers);
  }

  getApiInfo() {
    const url = new Array<string>(this.hostname, ApiEndpoint.Info).join('/');
    return this._http.get(url);
  }

  postResetPassword(user: User) {
    const url = new Array<string>(this.hostname, ApiEndpoint.Reset).join('/');
    return this._http.post(url, user);
  }

  putEmployeeStatus(status: AvatarStatus) {
    let url = new Array<string>(this.hostname, ApiEndpoint.Status).join('/');
    const body: EmployeeStatus = {
      text: status,
    };
    return this._http.put(url, body, { headers: this.setAuthHeader(), observe: 'response' });
  }

  getEmployeeStatus() {
    let url = new Array<string>(this.hostname, ApiEndpoint.Status).join('/');
    return this._http.get(url, { headers: this.setAuthHeader() });
  }
}
