import { Injectable, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService implements OnInit {
  duration: 1000;
  config = new MatSnackBarConfig();

  actionButtonLabel: string;

  constructor(public snackBar: MatSnackBar, private _translateService: TranslateService) {
    this.config.duration = this.duration;
    this.config.verticalPosition = 'top';

    this._translateService.get('app.btnDismiss')
    .subscribe(
      text => {
        this.actionButtonLabel = text;
      }
    );
  }

  ngOnInit() {

  }

  showInfoMessage(message: string, duration?: number) {
    if (duration) {
      this.config.duration = duration;
    } else {
      this.config.duration = this.duration;
    }

    return this.snackBar.open(message, this.actionButtonLabel, this.config);
  }
}
