import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

// types
import { SupportedLocales } from '@shared/locale-settings/supported-locales.enum';
import { SupportedLanguages } from '@shared/locale-settings/supported-languages.enum';

export class LocaleSettings {
    /** Current locale */
    locale: SupportedLocales;
    /** Current language */
    language: SupportedLanguages;
    /** Date interpreted as undefined/null by the API backend */
    nullDate: string;
    /** Default date format string */
    dateFormat: string;
    /** Default date format string for UTC */
    dateFormatUtc: string;
    /** Default date pipe */
    datePipe: DatePipe;

    constructor() {
        this.locale        = SupportedLocales.German;
        this.language      = SupportedLanguages.German;
        this.nullDate      = '0001-01-01T00:00:00Z';
        this.dateFormat    = 'yyyy-MM-ddTHH:mm:ss';
        this.dateFormatUtc = 'yyyy-MM-ddTHH:mm:ssZ';

        this.datePipe = new DatePipe(SupportedLocales.German);

        registerLocaleData(localeDe);
    }
}
