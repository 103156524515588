import { BirthdayListItem } from "@shared/birthday-list-item.interface";
import { Component, Input } from "@angular/core";
import { AvatarSize } from "../avatar/avatar-size.enum";
import { AvatarStatus } from "../avatar/avatar-status.enum";
import { Globals } from "@shared/globals";

@Component({
  selector: "shared-birthday-list-item",
  templateUrl: "./birthday-list-item.component.html",
  styleUrls: ["./birthday-list-item.component.css"],
})
export class BirthdayListItemComponent {
  @Input()
  public birthday: BirthdayListItem;

  public readonly globals: Globals;
  public readonly avatarSize = AvatarSize;
  public readonly avatarStatus = AvatarStatus;

  constructor(private _globals: Globals) {
    this.globals = this._globals;
  }
}
