import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ApiEndpoint } from '@services/api/api-endpoint.enum';
import { Globals } from '@shared/globals';
import { ApiCustomHeader } from '@services/api/api-custom-header.enum';
import { AppConfig } from '@shared/app-config/app-config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  hostname: string;
  jwtHelper: JwtHelperService;

  constructor(private _http: HttpClient,
              private _globals: Globals) {
    this.jwtHelper = new JwtHelperService();
    this._globals.appConfigInit$.subscribe({
      next: (config: AppConfig) => {
        if (config) {
          this.hostname = config.url;
        }
      }
    });
  }

  public authenticateJwt(user: string, password: string) {
    const login = { login: user, password: password };
    const url = new Array<string>(this.hostname, ApiEndpoint.Authenticate).join('/');

    let extendedHeaders = new HttpHeaders();
    extendedHeaders = extendedHeaders.append(ApiCustomHeader.ClientIdHeader, this._globals.appConfig.id);

    return this._http.post(url, login, { headers: extendedHeaders });
  }

  public isAuthenticated(): boolean {
    const accessToken = localStorage.getItem(this._globals.localStorageKeys.bearerToken);
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  }

  public isAuthenticatedJwt(): boolean {
    const token = localStorage.getItem(this._globals.localStorageKeys.bearerToken);
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  public logout() {
    localStorage.removeItem(this._globals.localStorageKeys.user);
    localStorage.removeItem(this._globals.localStorageKeys.bearerToken);
    localStorage.removeItem(this._globals.localStorageKeys.sessionId);
    sessionStorage.removeItem(this._globals.localStorageKeys.sessionId);
  }
}
