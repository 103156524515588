import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

import { EmployeeContact } from '@shared/api/employee-contact.interface';

import { Globals } from '@shared/globals';
import { ApiService } from '@services/api/api.service';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { Phone } from '@shared/api/phone.interface';
import { PhoneType } from '@shared/api/enum/phone-type.enum';
import { ContactDetailDialogComponent } from '@components/dialogs/contact-detail-dialog/contact-detail-dialog.component';
import { Themes } from '@shared/global-settings/themes.enum';
import { AvatarSize } from '@components/ui/avatar/avatar-size.enum';
import { AvatarStatus } from '@components/ui/avatar/avatar-status.enum';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css'],
})
export class EmployeesComponent implements OnInit {
  environment = environment;
  displayedColumns = ['isAtWork', 'firstName', 'lastName', 'phone', 'email', 'impersonate'];
  dataSource: MatTableDataSource<EmployeeContact>;
  resultsLength = 0;
  globals: Globals;
  isLoadingResults = true;
  searchValue = '';

  public readonly avatarSize = AvatarSize;
  public readonly avatarStatus = AvatarStatus;

  // selection model
  selection: SelectionModel<EmployeeContact>;
  initialSelection = [];
  allowMultiSelect = false;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInputField', { static: true }) searchInputField: ElementRef;
  @ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;

  constructor(
    private _apiService: ApiService,
    private _authGuardService: AuthGuardService,
    private _gravatarService: GravatarService,
    private _globals: Globals,
    public contactDetailDialog: MatDialog
  ) {
    this.globals = _globals;
  }

  ngOnInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: EmployeeContact, filter: string) => {
      filter = filter.toLocaleLowerCase();
      if (data.email.toLocaleLowerCase().indexOf(filter) !== -1) return true;
      if (data.firstName.toLocaleLowerCase().indexOf(filter) !== -1) return true;
      if (data.lastName.toLocaleLowerCase().indexOf(filter) !== -1) return true;
      if (String(data.isAtWork).indexOf(filter) !== -1) return true;
      for (let phoneObj of data.phones) {
        if (
          phoneObj.number.toLocaleLowerCase().indexOf(filter) !== -1 ||
          phoneObj.number.replace('0049', '0').indexOf(filter) !== -1 ||
          phoneObj.number.replace('0049', '+49').indexOf(filter) !== -1
        )
          return true;
      }
      if (data.isAtWork) {
        if (data.status.text === "")
          if ("available".indexOf(filter) !== -1) return true;
        if (data.status.text === AvatarStatus.Online)
          if ("available".indexOf(filter) !== -1) return true;
        if (data.status.text === AvatarStatus.Away)
          if ("away".indexOf(filter) !== -1) return true;
        if ("online".indexOf(filter) !== -1) return true;
      }
      else
        if ("offline".indexOf(filter) !== -1) return true;
      return false;
    };

    this.selection = new SelectionModel<EmployeeContact>(this.allowMultiSelect, this.initialSelection);

    this._globals.isLoading = true;
    this._apiService.getContacts().subscribe(
      (data) => {
        this.dataSource.data = data as EmployeeContact[];
        this.isLoadingResults = false;
        this._globals.isLoading = false;
        this.resultsLength = this.dataSource.data.length;
      },
      (error) => {
        if ((error as HttpErrorResponse).status === 401) {
          console.log('Unauthorized access please login again.');
          this._authGuardService.logout();
        }

        console.error("Couldn't load employee list.");
        this.isLoadingResults = false;
        this._globals.isLoading = false;
      }
    );

    this.searchInputField.nativeElement.focus();

    // global event handler
    this._globals.themeChangedEvent.subscribe((event) => {
      this.onThemeChanged(event);
    });

    this.onThemeChanged(this._globals.ui.currentTheme);
  }

  onThemeChanged(theme: Themes) {
    if (theme === Themes.ACXLightTheme) {
      this.searchContainer.nativeElement.classList.remove('acx-dark-colors');
      this.searchContainer.nativeElement.classList.add('acx-light-colors');
    }

    if (theme === Themes.ACXDarkTheme) {
      this.searchContainer.nativeElement.classList.remove('acx-light-colors');
      this.searchContainer.nativeElement.classList.add('acx-dark-colors');
    }
  }

  onSelect(row: EmployeeContact) {
    this.selection.clear();
    this.selection.toggle(row);

    const dialogRef = this.contactDetailDialog.open(ContactDetailDialogComponent, {
      data: row,
      panelClass: 'app-dialog-style',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getGravatar(email: string): string {
    return this._gravatarService.getGravatar(email, 35);
  }

  getWorkPhoneNumber(phones: Array<Phone>): string {
    let number: string;

    for (const phone of phones) {
      if (phone.type.toLowerCase() === PhoneType.WorkPhone) {
        number = phone.number;
      }

      if (phone.type.toLowerCase() === PhoneType.WorkMobile) {
        if (!number) {
          number = phone.number;
        }
      }
    }

    return number;
  }

  impersonate(employee: EmployeeContact) {
    if (this._globals.impersonate.isAdmin()) {
      this._globals.impersonate.setUser(
        employee,
        this._globals.localStorageKeys.user
      );
      this._globals.impersonatingStarted$.next(true);
    }
  }
}
