<div mat-dialog-container class="app-confirm-dialog action-row-bottom-fix mat-typography" fxLayout="column" fxLayoutAlign="space-evenly left" fxFlex="grow">
  <!-- header -->
  <h2 mat-dialog-title>{{'app.dialog.confirmTitle' | translate}}</h2>
  <!-- content -->
  <div>
    <mat-dialog-content class="mat-typography">
    <div class="dialog-message">
      <span>{{dialogData?.message}}</span>
    </div>
  </mat-dialog-content>
  </div>
  <!-- action -->
  <div class="spacer"></div>
  <div>
    <mat-divider></mat-divider>
    <mat-dialog-actions *ngIf="dialogData?.options" align="end">
      <ng-container *ngFor="let opt of dialogData?.options">
        <ng-container *ngIf="isConfirmationAction(opt); then confirmButton else cancelButton"></ng-container>
        <ng-template #confirmButton>
          <button mat-button color="primary" (click)="onConfirmAction()">{{'app.dialog.confirmOptionYes' | translate}}</button>
        </ng-template>
        <ng-template #cancelButton>
          <button mat-button mat-dialog-close>{{'app.dialog.confirmOptionNo' | translate}}</button>
        </ng-template>
      </ng-container>
    </mat-dialog-actions>
  </div>
</div>