import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { flatMap } from 'rxjs/operators';

// shared types
import { Globals } from '@shared/globals';
import { AppConfig } from '@shared/app-config/app-config';
import { Token } from '@shared/api/token';
import { Impersonate } from '@shared/global-settings/impersonate';

// services
import { AppConfigService } from '@services/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  isLoading = false;
  hasLoadedSuccessfully = false;
  subscriptions = new Subscription();

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(private _globals: Globals,
              private _appConfigService: AppConfigService,
              private _translateService: TranslateService) {
    this._translateService.setDefaultLang(_globals.localeSettings.language);
  }

  ngOnInit(): void {
    // subscribe to sidebar toggle event emitted by the toolbar burger menu
    this._globals.toggleSidebarEvent.subscribe(
      event => {
        this.onToggleSidenav(event);
      }
    );

    // try to load theme settings
    this._globals.ui.loadThemeSettings(this._globals.localStorageKeys.data.uiTheme);
    this._globals.setTheme(this._globals.ui.currentTheme, this._globals.ui.isDarkTheme);
    this._globals.themeChangedEvent.emit(this._globals.ui.currentTheme);

    // load application config
    this.isLoading = true;

    // get configs
    this.subscriptions.add(this._appConfigService.getAppConfig().subscribe({
        next: ([env, config]) => {
          this._globals.appConfig = new AppConfig(env.url, env.environment, config);

          // get token if it exists
          this._globals.token = new Token(localStorage.getItem(this._globals.localStorageKeys.bearerToken));
          if (this._globals.token) {
            this._globals.impersonate = new Impersonate(this._globals.token, this._globals.localStorageKeys.user);
          }

          // finished loading critical resources and settings
          this.isLoading = false;
          if (this._globals.appConfig.validate()) {
            this.hasLoadedSuccessfully = true;
            this._globals.appConfigInit$.next(this._globals.appConfig);
          } else {
            this.hasLoadedSuccessfully = false;
          } 
        },
        error: (err) => {
          this.isLoading = false;
          this.hasLoadedSuccessfully = false;
          console.warn(err);
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe;
    }
  }

  onToggleSidenav(event: any): void {
    this.sidenav.toggle();
  }
}
