
<div fxFill fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center center">
  <mat-card>
    <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
    <mat-card-content>
      <!-- Header -->
      <div fxLayout="column" fxLayoutAlign="center start">
          <ng-container *ngIf="globals?.ui.isDarkTheme; then darkThemeLogo else lightThemeLogo"></ng-container>
          
          <ng-template #darkThemeLogo>
              <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx-dark.svg" />
          </ng-template>

          <ng-template #lightThemeLogo>
              <img fxHide fxShow.gt-sm class="login-logo" src="assets/logo/acx.svg" />
          </ng-template>
          <span class="mat-headline">{{'app.pages.login.welcomeTitle' | translate}} HR-Portal!</span>
      </div>
      <!-- Content -->
      <form #loginForm="ngForm" (ngSubmit)="onSubmitLoginJwt()">
        <mat-form-field>
          <input matInput 
                 tabindex="1"
                 required
                 cdkInitialFocus
                 name="username"
                 placeholder="{{'app.pages.login.placeholderEnterAccountName' | translate}}"
                 [formControl]="formControlUser">
          <mat-error>{{getUsernameErrors()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                 tabindex="2"
                 required
                 [type]="visibilityToggle ? 'text' : 'password'"
                 name="password"
                 placeholder="{{'app.pages.login.placeholderEnterPassword' | translate}}"
                 [formControl]="formControlPassword">
          <button mat-button type="button" tabindex="4" *ngIf="formControlPassword.value" matSuffix mat-icon-button aria-label="Show" (click)="visibilityToggle=!visibilityToggle">
            <ng-container *ngIf="visibilityToggle;then icon_off else icon_on"></ng-container>
            <ng-template #icon_off><mat-icon>visibility_off</mat-icon></ng-template>
            <ng-template #icon_on><mat-icon>visibility</mat-icon></ng-template>
          </button>
          <mat-error>{{getPasswordErrors()}}</mat-error>
        </mat-form-field>
        <!-- Message and action area -->
        <div class="login-actions" fxLayout="column">
          <!-- Actions -->
          <div fxLayout="row" fxLayout="start stretch">
            <a routerLink="/login-reset">{{'app.pages.login.btnResetPassword' | translate}}?</a>
            <div class="spacer"></div>
            <button tabindex="3" class="form-submit" [disabled]="isLoading" mat-raised-button color="primary" type="submit">{{'app.pages.login.btnNext' | translate}}</button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

