<mat-sidenav-container #sidenavContainer class="app-container" autosize>
    <mat-sidenav #sidenav class="app-sidenav" mode="over">
        <div fxLayout="column">
            <div fxLayout="row">
                <div class="spacer"></div>
                <button mat-icon-button (click)="onToggleSidenav($event)"><mat-icon>close</mat-icon></button>
            </div>
            <button mat-button color="primary" routerLink="/home" (click)="onToggleSidenav($event)">{{'app.toolbar.navHome' | translate}}</button>
            <button mat-button color="primary" routerLink="/employees" (click)="onToggleSidenav($event)">{{'app.toolbar.navEmployeeDirectory' | translate}}</button>
            <button mat-button color="primary" routerLink="/work-hours" (click)="onToggleSidenav($event)">{{'app.toolbar.navBookWorkHours' | translate}}</button>
        </div>
    </mat-sidenav>

    <mat-sidenav-content style="overflow: auto;">
        <ng-container *ngIf="isLoading; then loadingContent else appContent"></ng-container>
        
        <!-- Loading Content -->
        <ng-template #loadingContent>
            <div fxLayout="row" fxLayoutAlign="center stretch" fxFill>
                <div fxLayout="column" fxLayoutAlign="center center" fxFlex="grow">
                    <div class="spacer"></div>
                    <span class="mat-headline loading-content-text">{{'app.message.loadingApp' | translate}}</span>
                    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                    <div class="spacer"></div>
                </div>
            </div>
        </ng-template>

        <!-- App Content -->
        <ng-template #appContent>
            <ng-container *ngIf="hasLoadedSuccessfully; then renderAppContent else renderErrorContent"></ng-container>
            
            <!-- Render App Content -->
            <ng-template #renderAppContent>
                <router-outlet></router-outlet>
            </ng-template>

            <!-- Render Error Content -->
            <ng-template #renderErrorContent>
                <div fxLayout="row" fxLayoutAlign="center stretch" fxFill>
                        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="grow">
                            <div class="spacer"></div>
                            <span class="mat-headline loading-content-text">{{'app.message.loadingAppFailed' | translate}}</span>
                            <div class="spacer"></div>
                        </div>
                    </div>
            </ng-template>
        </ng-template>
    </mat-sidenav-content>
</mat-sidenav-container>
