export class Base64 {
    constructor() { }

    public static encodeUnicode(text: string) {
        return btoa(encodeURIComponent(text).replace(new RegExp('%([0-9A-F]{2})', 'g'),
            function toSolidBytes(match, p1) {
                const charNum: number = parseInt('0x' + p1, 16);
                return String.fromCharCode(charNum);
        }));
    }

    public static decodeUnicode(unicode: string) {
        return decodeURIComponent(atob(unicode).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
}
