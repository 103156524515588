<div mat-dialog-container class="app-dialog-leave-details action-row-bottom-fix mat-typography" fxLayout="column">
  <span class="mat-title">{{'app.dialog.statsDetailsTitle' | translate}}</span>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70" fxHide fxShow.gt-sm>{{'app.dialog.statsPaidLeaveRemaining' | translate}} ({{'app.dialog.statsPreviousYear' | translate}})</span>
    <span fxFlex="70" fxHide.gt-sm fxShow>{{'app.dialog.statsPaidLeaveRemaining' | translate}} ({{'app.dialog.statsPreviousYearMobile' | translate}})</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{data.paidLeaveCarriedOver}}</span>
  </div>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70" fxHide fxShow.gt-sm>{{'app.dialog.statsPaidLeaveEntitlement' | translate}} ({{'app.dialog.statsCurrentYear' | translate}})</span>
    <span fxFlex="70" fxHide.gt-sm fxShow>{{'app.dialog.statsPaidLeaveEntitlement' | translate}} ({{'app.dialog.statsCurrentYearMobile' | translate}})</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{data.paidLeave}}</span>
  </div>
  <div class="details-div" fxLayout="row">
    <span fxFlex="20" matTooltip="{{data.paidLeaveTakenToday}} {{'app.dialog.statsPaidLeaveTakenToday' | translate}}" matTooltipPosition="after">{{'app.dialog.statsPaidLeaveClaimed' | translate}}*</span>
    <span fxFlex="50"></span>
    <span fxLayoutAlign="end center" fxFlex="20">{{data.paidLeaveTaken}}</span>
  </div>
  <mat-divider></mat-divider>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70">{{'app.dialog.statsPaidLeaveRemaining' | translate}}</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{(data.paidLeave + data.paidLeaveCarriedOver) - data.paidLeaveTaken}}</span>
  </div>
  <button mat-button mat-dialog-close>{{'app.btnClose' | translate}}</button>
</div>
