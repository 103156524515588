export enum ApiEndpoint {
  Presence = 'presence',
  Employee = 'employee',
  Authenticate = 'auth',
  Workhours = 'workhours',
  Stats = 'stats',
  Contacts = 'contacts',
  Info = 'info',
  Reset = 'auth/resetpassword',
  Change = 'auth/changepassword',
  Recover = 'auth/recoverpassword',
  Status = 'status',
}
