<div mat-dialog-container class="app-dialog-leave-details action-row-bottom-fix mat-typography" fxLayout="column">
  <span class="mat-title">{{'app.dialog.statsDetailsOvertimeTitle' | translate}}</span>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70">{{'app.pages.home.statsOvertime' | translate}} {{data.validityDate | date:'dd.MM.yyyy'}}</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{currentOvertime.toString('hh:mm')}} h</span>
  </div>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70">{{'app.pages.home.statsWeeklyWorkTime' | translate}}</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{weeklyHours.toString('hh:mm')}} h</span>
  </div>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70">{{'app.pages.home.statsWorktimeWeekly' | translate}}</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{weeklyHoursWorked.toString('hh:mm')}} h</span>
  </div>
  <mat-divider></mat-divider>
  <div class="details-div" fxLayout="row">
    <span fxFlex="70">{{'app.dialog.statsOvertimeResult' | translate}} ({{today | date: 'dd.MM.yyyy'}})</span>
    <span fxLayoutAlign="end center" fxFlex="20">{{calculateCurrentOvertime()}} h</span>
  </div>
  <button mat-button mat-dialog-close>{{'app.btnClose' | translate}}</button>
</div>
