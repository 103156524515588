import { AppSettings } from '@shared/app-config/app-settings';
import { validateBasis } from '@angular/flex-layout';

/** JSON based application configuration */
export class AppConfig {
    /** Application specific details */
    app: AppSettings;
    /** Application/Client ID for identification with the backend API */
    id: string;
    /** Application version */
    version: string;
    /** app environment (staging | production) */
    environment: 'staging' | 'production';

    url: string;

    constructor(url: string, environment: 'staging' | 'production', config?: any) {
        this.app = new AppSettings();
        this.environment = 'staging';

        if (config) {
            this.id = config.id;
            this.version = config.version;
            this.app.name = config.app.name;
            this.app.viewName = config.app.viewName;
            this.app.description = config.app.description;
            this.app.copyright = config.app.copyright;
            this.environment = environment ? environment : 'staging';
            this.url = url;
        }

    }

    validate(): boolean {
        let validated = false;
        if (this.id && this.environment && this.url && this.version && this.app.name && this.app.copyright) {
            validated = true;
        }

        return validated;
    }
}
