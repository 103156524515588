import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatsOvertime } from '@shared/stats-overtime.interface';
import { Time } from '@shared/time';

@Component({
  selector: 'app-stats-detailed-info-overtime-dialog',
  templateUrl: './stats-detailed-info-overtime-dialog.component.html',
  styleUrls: ['./stats-detailed-info-overtime-dialog.component.css']
})
export class StatsDetailedInfoOvertimeDialogComponent implements OnInit {
  weeklyHours = new Time();
  weeklyHoursWorked = new Time();
  currentOvertime = new Time();

  today = new Date(Date.now());

  constructor(public dialogRef: MatDialogRef<StatsDetailedInfoOvertimeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: StatsOvertime) { }

  ngOnInit() {
    this.weeklyHours = Time.parseNumber(this.data.weeklyHours);
    this.weeklyHoursWorked = Time.parseNumber(this.data.workingHoursThisWeek);
    this.currentOvertime = Time.parseNumber(this.data.overtime);
  }

  calculateCurrentOvertime(): string {
    let overtime = new Time();
    const value = this.data.overtime + (this.data.workingHoursThisWeek - this.data.weeklyHours);
    overtime = Time.parseNumber(value);

    return overtime.toString('hh:mm');
  }

}
