import { Themes } from '@shared/global-settings/themes.enum';
import { Globals } from '@shared/globals';

export class CustomThemes {
    themes: Array<string>;
    currentTheme: Themes;
    isDarkTheme: boolean;

    constructor(private _globals?: Globals) {
        this.themes = new Array<string>();
    }

    saveThemeSettings(localStorageKey: string) {
        localStorage.setItem(localStorageKey, JSON.stringify(this));
    }

    loadThemeSettings(localStorageKey: string) {
        const data = localStorage.getItem(localStorageKey);
        if (data) {
            const jsonObj = JSON.parse(data);
            if (jsonObj) {
                this.clone(jsonObj);
            }
        }
    }

    clone(dataObj: any) {
        this.themes = dataObj.themes;
        this.currentTheme = dataObj.currentTheme;
        this.isDarkTheme = dataObj.isDarkTheme;
    }
}
