<app-toolbar></app-toolbar>
<div class="toolbar-content-spacer"></div>
<div *ngIf="globals?.appConfig?.environment!=='production'" class="toolbar-content-spacer-test"></div>
<div class="stats-container" fxLayout="column" fxLayout.gt-sm="row">
  <div fxLayout="column" fxFlex.gt-sm="50">
    <span class="mat-headline">{{'app.pages.home.statsHeadline' | translate}}</span>
    <mat-divider></mat-divider>
    <div fxLayout="row">
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="0 0 250px" fxHide fxShow.gt-sm>{{'app.pages.home.statsOvertime' | translate}} {{statsDate| date: 'dd.MM.yyyy'}}</span>
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxHide.gt-sm fxShow>{{'app.pages.home.statsOvertimeMobile' | translate}}</span>
        <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20">
            <ng-container *ngIf="overtimeInHours">
                {{overtimeInHours?.toString('hh:mm')}} h
            </ng-container>
        </span>
        <span fxFlex><button *ngIf="stats" mat-icon-button matTooltip="{{'app.pages.home.tooltipStatDetailsOvertime' | translate}}" matTooltipPosition="{{paidLeaveTooltipPos}}" (click)="onOpenStatsDetailedOvertimeInfoDialog()" i18n-matTooltip><mat-icon>info</mat-icon></button></span>
    </div>
    <div fxLayout="row">
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="0 0 250px" fxHide fxShow.gt-sm>{{'app.pages.home.statsPaidLeave' | translate}}</span>
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxHide.gt-sm fxShow>{{'app.pages.home.statsPaidLeaveMobile' | translate}}</span>
        <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20">
            <ng-container *ngIf="stats" >
                {{(stats?.paidLeave + stats?.paidLeaveCarriedOver) - stats?.paidLeaveTaken}} d
            </ng-container>
        </span>
        <span fxFlex><button *ngIf="stats" mat-icon-button matTooltip="{{'app.pages.home.tooltipStatDetails' | translate}}" matTooltipPosition="{{paidLeaveTooltipPos}}" (click)="onOpenStatsDetailedInfoDialog()" i18n-matTooltip><mat-icon>info</mat-icon></button></span>
    </div>
    <div fxLayout="row">
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxFlex.gt-sm="0 0 250px" >{{'app.pages.home.statsSickLeave' | translate}}</span>
        <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20"><ng-container *ngIf="stats">{{stats?.sickLeave}} d</ng-container></span>
    </div>
    <div fxLayout="row">
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxFlex.gt-sm="0 0 250px" >{{'app.pages.home.statsSickLeaveChild' | translate}}</span>
        <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20"><ng-container *ngIf="stats">{{stats?.sickLeaveChild}} d</ng-container></span>
    </div>
    <!-- Virtual Stats -->
    <span class="mat-headline">{{'app.pages.home.statsVirtualHeadline' | translate}}</span>
    <mat-divider></mat-divider>
    <div fxLayout="row">
            <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="0 0 250px" fxHide fxShow.gt-sm>{{'app.pages.home.statsWeeklyWorkTime' | translate}}</span>
            <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxHide.gt-sm fxShow>{{'app.pages.home.statsWeeklyWorkTimeMobile' | translate}}</span>
            <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20"><ng-container *ngIf="weeklyHours">{{weeklyHours?.toString('hh:mm')}} h</ng-container></span>
    </div>
    <div fxLayout="row">
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="0 0 250px" fxHide fxShow.gt-sm>{{'app.pages.home.statsWorktimeWeekly' | translate}}</span>
        <span fxLayoutAlign="start center" class="mat-subheading-1 stats-info-row" fxFlex="65" fxHide.gt-sm fxShow>{{'app.pages.home.statsWorktimeWeeklyMobile' | translate}}</span>
        <span fxLayoutAlign="end center" class="mat-subheading-1 stats-info-row" fxFlex="20"><ng-container *ngIf="weeklyWorktime">{{weeklyWorktime?.toString('hh:mm')}} h</ng-container></span>
    </div>
  </div>
  <div fxLayout="column" ngClass.gt-sm="birthday-margin" fxFlex.gt-sm="50">
    <!-- Birthdays -->
    <span class="mat-headline">{{'app.pages.home.statsBirthdaysHeadline' | translate}}</span>
    <mat-divider></mat-divider>
    <shared-birthday-list></shared-birthday-list>
  </div>
</div>
