import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AuthGuardService } from '@services/authentication/auth-guard.service';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api/api.service';
import { Presence, PresenceEntry } from '@shared/api/presence.interface';
import { Globals } from '@shared/globals';
import { AuthService } from '@services/authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AppInfoDialogComponent } from '@components/dialogs/app-info-dialog/app-info-dialog.component';
import { ConfirmDialogData } from '@shared/confirm-dialog-data.interface';
import { ConfirmDialogOptions } from '@shared/confirm-dialog-options.enum';
import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Time } from '@shared/time';
import { timer, Subscription } from 'rxjs';
import { GravatarService } from '@services/gravatar/gravatar.service';
import { Themes } from '@shared/global-settings/themes.enum';
import { formatISO } from 'date-fns';
import { AvatarSize } from '@components/ui/avatar/avatar-size.enum';
import { AvatarStatus } from '../avatar/avatar-status.enum';
import { EmployeeStatus } from '@shared/api/employee-status';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  environment = environment;
  cTime = Time;
  presenceList: Presence;
  isLoading = true;
  hasStartedWork = false;
  hasLeftForToday = false;
  globals: Globals;

  public readonly avatarSize = AvatarSize;

  today = new Date(Date.now());

  // ui ngModel
  uiStatusSlideToggle = false;
  uiThemeSlideToggle = false;

  // show online-time for finish work btn
  duration: number;
  canStartTimer = false;
  startTime: Date;
  onlineTimeObservable = timer(1000, 1000);
  onlineTimerSubscription: Subscription;

  // translations
  translationConfirmFinishWork: string;

  // events
  @Output() hasFinishedWorkEvent = new EventEmitter();
  @Output() hasStartedWorkEvent = new EventEmitter();

  // native elements
  @ViewChild('menuTextProfileName', { static: true }) menuTextProfileName: ElementRef;
  @ViewChild('menuTextThemeToggle', { static: true }) menuTextThemeToggle: ElementRef;

  constructor(
    private _authGuardService: AuthGuardService,
    private _apiService: ApiService,
    private _router: Router,
    private _globals: Globals,
    private _authService: AuthService,
    private _translate: TranslateService,
    private _gravatarService: GravatarService,
    public appInfoDialog: MatDialog,
    public confirmDialog: MatDialog
  ) {
    this.globals = _globals;
  }

  ngOnInit() {
    this.initializeComponent();

    this._globals.impersonatingStarted$.subscribe((value: boolean) => {
      this.initializeComponent();
    });
  }

  ngOnDestroy() {
    if (this.onlineTimerSubscription) {
      this.onlineTimerSubscription.unsubscribe();
    }
  }

  private initializeComponent() {
    this.checkPresenceStatus();

    this._translate.get("app.dialog.confirmFinishWork").subscribe((result) => {
      this.translationConfirmFinishWork = result;
    });

    this.onlineTimerSubscription = this.onlineTimeObservable.subscribe(
      (value) => {
      this.actWorkingTime();
      }
    );

    // global event handler
    this._globals.themeChangedEvent.subscribe((event) => {
      this.onThemeChanged(event);
    });
    this.onThemeChanged(this._globals.ui.currentTheme);
    this.uiThemeSlideToggle = this._globals.ui.isDarkTheme;
  }

  actWorkingTime() {
    if (this.canStartTimer) {
      const timeNow = new Date(Date.now());

      let currentDuration = 0;
      this.duration = currentDuration;

      // calculate current duration if time has passed
      if (timeNow.getTime() > this.startTime.getTime()) {
        currentDuration = timeNow.getTime() - this.startTime.getTime();
      }

      // only set new duration if time has passed & its at least 1 sec ahead
      if (this.duration !== currentDuration) {
        this.duration = currentDuration;
      }
    }
  }

  onStatusSlideToggle(event: any) {
    const previousStatus = this._globals.userStatus$.value;
    if (event === true) {
      this.updateEmployeeStatus(AvatarStatus.Away, this._globals.userStatus$.value);
    } else {
      this.updateEmployeeStatus(AvatarStatus.Online, this._globals.userStatus$.value);
    }
  }

  onThemeSlideToggle(event: any) {
    if (event === true) {
      // switch to dark theme
      this._globals.setTheme(Themes.ACXDarkTheme, true, Themes.ACXLightTheme);
    } else {
      // switch to light theme
      this._globals.setTheme(Themes.ACXLightTheme, false, Themes.ACXDarkTheme);
    }
  }

  onThemeChanged(theme: Themes) {
    if (theme === Themes.ACXLightTheme) {
      if (this.menuTextProfileName) {
        this.menuTextProfileName.nativeElement.classList.remove('acx-dark-colors');
        this.menuTextProfileName.nativeElement.classList.add('acx-light-colors');
      }

      if (this.menuTextThemeToggle) {
        this.menuTextThemeToggle.nativeElement.classList.remove('acx-dark-colors');
        this.menuTextThemeToggle.nativeElement.classList.add('acx-light-colors');
      }
    }

    if (theme === Themes.ACXDarkTheme) {
      if (this.menuTextProfileName) {
        this.menuTextProfileName.nativeElement.classList.remove('acx-light-colors');
        this.menuTextProfileName.nativeElement.classList.add('acx-dark-colors');
      }

      if (this.menuTextThemeToggle) {
        this.menuTextThemeToggle.nativeElement.classList.remove('acx-light-colors');
        this.menuTextThemeToggle.nativeElement.classList.add('acx-dark-colors');
      }
    }
  }

  onStopImpersonateClick(event: any) {
    this._globals.impersonate.stopImpersonate();
    this._globals.impersonatingStarted$.next(false);
    this._router.navigate(['/contacts']);
  }

  onToggleSidebar(event: any) {
    this._globals.toggleSidebarEvent.emit();
  }

  onLogoutClick(event: any) {
    this._authGuardService.logout();
  }

  onProfileClick(event: any) {
    this._router.navigate(['profile']);
  }

  onFinishWorkClick(event: any) {
    const dialogData: ConfirmDialogData = {
      message: this.translationConfirmFinishWork,
      options: [ConfirmDialogOptions.No, ConfirmDialogOptions.Yes],
    };

    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: 'app-dialog-style',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoading = true;
        this._apiService.getPresence(this.today, this.today, this.today).subscribe(
          (todayPresence) => {
            const presenceList = { presence: todayPresence as PresenceEntry[] };

            if (presenceList.presence.length === 1) {
              const entryUpdate: PresenceEntry = {
                begin: presenceList.presence[0].begin,
                end: formatISO(Date.now()),
              };

              this._apiService.putPresence(this.today, entryUpdate).subscribe(
                (reply) => {
                  console.log('Good bye', reply);
                  this.hasStartedWork = false;
                  this.hasLeftForToday = true;
                  this.updateEmployeeStatus(AvatarStatus.Offline, this._globals.userStatus$.value);
                  this.isLoading = true;
                  this.hasFinishedWorkEvent.emit();
                },
                (error) => {
                  console.warn('Leave work failed ', error);
                }
              );
            }
          },
          (error) => {
            this.isLoading = false;
            if ((error as HttpErrorResponse).status === 401) {
              console.log('Unauthorized access please login again.');
              this._authGuardService.logout();
            }

            console.warn('Leave work failed ', error);
          }
        );
      }
    });
  }

  onStartWorkClick(event: any) {
    const formattedDate = formatISO(this.today);
    this.isLoading = true;

    const data: PresenceEntry = {
      begin: formattedDate,
      end: this._globals.localeSettings.nullDate,
    };
    this._apiService.postPresence(this.today, data).subscribe(
      (response) => {
        console.log(response);
        this.isLoading = false;
        this.checkPresenceStatus();
        this.updateEmployeeStatus(AvatarStatus.Online, this._globals.userStatus$.value);
        this.hasStartedWorkEvent.emit();
      },
      (error) => {
        this.isLoading = false;
        if ((error as HttpErrorResponse).status === 401) {
          console.log('Unauthorized access please login again.');
          this._authGuardService.logout();
        }
      }
    );
  }

  onRemoveTokensClick(event: any) {
    this._authService.logout();
  }

  onOpenInfoDialog(event: any) {
    const dialogRef = this.appInfoDialog.open(AppInfoDialogComponent, {
      data: {},
      panelClass: 'app-dialog-style',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onResetStartLeaveClick(event: any) {
    this._apiService.resetPresence(this.today).subscribe(
      (response) => {
        this.isLoading = true;
        this.hasStartedWork = false;
        this.hasLeftForToday = false;
        this.checkPresenceStatus();
      },
      (error) => {
        if ((error as HttpErrorResponse).status === 401) {
          console.log('Unauthorized access please login again.');
          this._authGuardService.logout();
        }
      }
    );
  }

  private updateEmployeeStatus(newStatus: AvatarStatus, previousStatus: AvatarStatus) {
    this._globals.userStatus$.next(newStatus);
    this._apiService.putEmployeeStatus(newStatus).subscribe((response) => {
      if (response.status !== 200) {
        this._globals.userStatus$.next(previousStatus);
      }
      if (this._globals.userStatus$.value === AvatarStatus.Offline) {
        this.uiStatusSlideToggle = false;
      }
    });
  }

  private checkEmployeeStatus() {
    this._apiService.getEmployeeStatus().subscribe((response: EmployeeStatus) => {
      this._globals.userStatus$.next(
        response.text && this.hasStartedWork
          ? (response.text.toLocaleUpperCase() as AvatarStatus)
          : AvatarStatus.Offline);

      switch (this._globals.userStatus$.value) {
        case AvatarStatus.Offline:
          this.uiStatusSlideToggle = false;
          break;
        case AvatarStatus.Online:
          this.uiStatusSlideToggle = false;
          break;
        case AvatarStatus.Away:
          this.uiStatusSlideToggle = true;
          break;
      }
    });
  }

  private checkPresenceStatus() {
    this._apiService.getPresence(this.today, this.today).subscribe(
      (response) => {
        this.presenceList = { presence: response as PresenceEntry[] };
        this.isLoading = false;
        let isTodayInReply = false;

        for (const entry of this.presenceList.presence) {
          const parsedWorkBegin = new Date(Date.parse(entry.begin));
          const parsedWorkEnd = new Date(Date.parse(entry.end));

          if (parsedWorkBegin) {
            this.canStartTimer = true;
            this.startTime = new Date(parsedWorkBegin);

            // check if response includes todays date
            if (
              this.today.getFullYear() === parsedWorkBegin.getFullYear() &&
              this.today.getMonth() === parsedWorkBegin.getMonth() &&
              this.today.getDate() === parsedWorkBegin.getDate()
            ) {
              // if todays date has work end already set
              const nullDate = new Date(Date.parse(this._globals.localeSettings.nullDate));
              if (parsedWorkEnd.valueOf() !== nullDate.valueOf()) {
                this.hasLeftForToday = true;
              } else {
                this.hasStartedWork = true;
                this.isLoading = false;
              }
              isTodayInReply = true;
              break;
            }
          }

          if (isTodayInReply === false) {
            this.isLoading = false;
          }
        }

        this.checkEmployeeStatus();
      },
      (error) => {
        if ((error as HttpErrorResponse).status === 401) {
          console.log('Unauthorized access please login again.');
          this._authGuardService.logout();
        }

        console.warn('Error requesting presence', error);
      }
    );
  }
}
